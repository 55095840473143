import React from 'react';
import { GDPRContainer } from './src/components';

export const wrapRootElement = ({ element }) => {
	return (
		<>
			{element}
			<GDPRContainer />
		</>
	);
};

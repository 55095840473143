import React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { CareerPostMenu } from '../components';
import { BASE_URL, CONFIG } from '../utils/api';

const CareerRoles = () => {
	const { t } = useTranslation();

	const [careerRoles, setCareerRoles] = useState([]);

	useEffect(() => {
		const fetchJobPosts = async () => {
			const response = await axios.get(`${BASE_URL}/Posts`, CONFIG);
			setCareerRoles(response.data.records);
		};
		fetchJobPosts();
	}, []);

	const departmentRoles = useMemo(() => {
		return careerRoles.reduce((acc, cur) => {
			if (!acc[cur.fields.Department]) {
				acc[cur.fields.Department] = [cur];
			} else {
				acc[cur.fields.Department].push(cur);
			}

			return acc;
		}, {});
	}, [careerRoles]);

	return (
		<div className="career-roles">
			<p className="subtitle">{t('careers.roles.subtitle')}</p>
			<h1 className="main-title semibold">{t('careers.roles.title')}</h1>
			{Object.entries(departmentRoles).map(([department, roles]) => {
				return (
					<CareerPostMenu
						key={`department-roles-${department}`}
						department={department}
						roles={roles}
					/>
				);
			})}
		</div>
	);
};

export default CareerRoles;

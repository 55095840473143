import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import QRModal from '/src/components/qr-modal';

const DownloadButton = ({ outline, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = useCallback(() => {
		if (!isOpen) {
			window.analytics.track('Download Link Clicked');
		}

		setIsOpen(!isOpen);
	}, [isOpen]);

	const classes = useMemo(() => {
		return classNames({ outline });
	}, [outline]);

	const desktopClasses = useMemo(() => {
		return classNames(classes, 'desktop-screen');
	}, [classes]);

	return (
		<>
			<button
				className={desktopClasses}
				onClick={toggle}>
				{children}
			</button>

			<a
				className="mobile-screen download-button-wrapper"
				href="https://trydriver.onelink.me/nCLt/6df40807?af_qr=true"
				target="_blank"
				rel="noreferrer">
				<button className={classes}>{children}</button>
			</a>

			<QRModal isOpen={isOpen} toggle={toggle} />
		</>
	);
};

DownloadButton.defaultProps = {
	outline: false,
};

export default DownloadButton;

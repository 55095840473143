import React from 'react';
import i18next from './config';
import { I18nextProvider, withTranslation } from 'react-i18next';

/* eslint-disable react/display-name */
export function withTrans(Component) {
	Component = withTranslation()(Component);

	return class extends React.Component {
		render() {
			return (
				<I18nextProvider i18n={i18next}>
					<Component {...this.props} language={i18next.language} />
				</I18nextProvider>
			);
		}
	};
}
/* eslint-enable react/display-name */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const MessageForm = () => {
	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [isDisabled, setIsDisabled] = useState(true);

	useEffect(() => {
		if (!!name && !!phone && !!email && !!subject && !!message) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [name, phone, email, subject, message]);

	const onSubmit = e => {
		e.preventDefault();

		window.location.href = `
			mailto:info@trydriver.com
			?subject=${encodeURIComponent(subject)}
			&body=${encodeURIComponent(message)}
			%0D%0A%0D%0A${encodeURIComponent(name)}
			%0D%0A%0D%0A${encodeURIComponent(phone)}
			%0D%0A%0D%0A${encodeURIComponent(email)}
		`;
	};

	return (
		<section className="message-form">
			<div>
				<h3>{t('form.heading')}</h3>
				<h5 className="black semibold">{t('form.subheading')}</h5>
			</div>
			<form onSubmit={onSubmit}>
				<div>
					<input
						type="text"
						name="name"
						placeholder={t('form.name')}
						onChange={({ target }) => {
							setName(target.value);
						}}
					/>
				</div>
				<div>
					<input
						type="text"
						name="phone"
						size="1"
						placeholder={t('form.phone')}
						onChange={({ target }) => {
							setPhone(target.value);
						}}
					/>
					<input
						type="email"
						name="email"
						size="1"
						placeholder={t('form.email')}
						onChange={({ target }) => {
							setEmail(target.value);
						}}
					/>
				</div>
				<div>
					<input
						type="text"
						name="subject"
						placeholder={t('form.subject')}
						onChange={({ target }) => {
							setSubject(target.value);
						}}
					/>
				</div>
				<div>
					<textarea
						name="message"
						placeholder={t('form.message')}
						onChange={({ target }) => {
							setMessage(target.value);
						}}
					/>
				</div>
				<div>
					<button
						type="submit"
						className={classNames({ disabled: isDisabled })}>
						{t('form.send')}
					</button>
				</div>
			</form>
		</section>
	);
};

export default MessageForm;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MARBLE, STABLE } from '../constants';

const PartnerBanner = ({ icon, organization }) => {
	const { t } = useTranslation();

	const handleClick = () => {
		if (organization !== STABLE && organization !== MARBLE) {
			document.querySelector('.form-outer-wrap').scrollIntoView();
		} else {
			document.querySelector('#register').scrollIntoView();
		}
	};

	return (
		<div className="banner">
			<div className="filter" />
			<div className="wrap">
				{!!icon && (
					<div className="icon">
						<img src={icon} alt="partners logo" />
					</div>
				)}

				{organization === STABLE ? (
					<p className="heading bold">{t('partner.the-world-most-intelligent')}</p>
				) : (
					<p className="heading bold">{t('partner.protection-for-you')}</p>)
				}

				<p className="subheading semibold">{t('partner.have-all-the-evidence')}</p>
				<button
					className="sign-up-btn"
					onClick={handleClick}>
					{t('partner.sign-up-here')}
				</button>
			</div>
		</div>
	);
};

export default PartnerBanner;

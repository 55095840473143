import React from 'react';

const TermsAndConditionsEN = () => {
	return (
		<section className="container">
			<p className="bold">Last Updated: August 1st, 2019</p>

			<p>Welcome, and thank you for your interest in Driver Technologies Inc. (“Driver,” “we,” or “us”) and our website at www.trydriver.com, along with our related websites, networks, applications, mobile applications, and other services provided by us (collectively, the “Service”). These Terms of Service are a legally binding contract between you and Driver regarding your use of the Service.</p>

			<p className="bold">PLEASE READ THE FOLLOWING TERMS CAREFULLY.</p>

			<p>BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS (TOGETHER, THESE “TERMS”). If you are not eligible, or do not agree to the Terms, then you do not have our permission to use the Service.  YOUR USE OF THE SERVICE, AND Driver’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY Driver AND BY YOU TO BE BOUND BY THESE TERMS.</p>

			<p>Arbitration NOTICE. Except for certain kinds of disputes described in Section 20, you agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND Driver ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under this contract (except for matters that may be taken to small claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See Section 20.)</p>

			<h4>1.Driver Service Overview.</h4>
			<p>Driver provides a platform that allows drivers to monitor and record certain information arising from their trips.</p>

			<h4>2.Safety While Using the Service and Damage to Person or Property.</h4>
			<p>WITHOUT LIMIITING ANY OTHER TERMS SET FORTH IN THESE TERMS, AND TO THE FULLEST EXTENT PERMITTED BY LAW:</p>

			<p>WHEN YOU USE THE SERVICE, YOU MAY FIND THAT THE ACTUAL CONDITIONS DIFFER FROM THE NAVIGATION SUGGESTIONS OR SAFETY ALERTS PROVIDED WITHIN THE SERVICE OR THE SERVICE CONTAINS INACCURACIES, SO YOU MUST EXERCISE YOUR INDEPENDENT JUDGMENT WHEN YOU USE THE SERVICE, AND YOU USE THE SERVICE SOLELY AT YOUR OWN RISK. YOU MAY ONLY ACCESS THE SERVICE WHEN IT IS SAFE TO DO SO AS DETERMINED SOLELY BY YOU IN YOUR SOLE JUDGMENT. WHILE USING THE SERVICE, YOU MUST OBEY ALL LAWS, RULES AND REGULATIONS, INCLUDING ALL TRAFFIC RULES AND TRAFFIC REGULATIONS GOVERNING THE OPERATION OF YOUR VEHICLE AND/OR USE OF YOUR MOBILE DEVICE, AND YOU WILL NOT ACCESS AND/OR USE THE SERVICE IN A MANNER THAT VIOLATES ANY LAW, RULE, OR REGULATION. IT IS YOUR SOLE RESPONSIBILITY TO EXERCISE DISCRETION AND OBSERVE ALL SAFETY MEASURES REQUIRED BY ALL LAWS, INCLUDING ALL TRAFFIC RULES AND TRAFFIC REGULATIONS. YOU ARE RESPONSIBLE AT ALL TIMES FOR YOUR CONDUCT AND ANY CONSEQUENCES RELATED TO YOUR CONDUCT IN CONNECTION WITH YOUR USE OF THE SERVICE.</p>

			<p>YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE OR HARM TO ANY PROPERTY OR PERSON (INCLUDING TO YOUR OWN PROPERTY AND PERSON) IN CONNECTION WITH YOUR USE OF THE SERVICE, AND YOU ACKNOWLEDGE AND AGREE THAT WE WILL HAVE NO LIABILITY TO YOU OR ANY THIRD PARTY FOR THE SAME UNDER ANY CIRCUMSTANCES REGARDLESS OF THE CAUSE OR ACTION.</p>

			<h4>3.Eligibility.</h4>
			<p>You must be at least 16 years old to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) either you are at least 16 years of age or have been authorized to use the Service by your parent or legal guardian who is at least 18 years of age and who will supervise your use of the Service; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms</p>

			<h4>4.Accounts and Registration.</h4>
			<p>To access most features of the Service, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your name, email address, phone number or other contact information. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times. We may ask you to provide us with additional information such as how you anticipate to use the Service, where you like to drive, and other driving preferences. You are solely responsible for maintaining the confidentiality of your account, and you accept responsibility for all activities that occur under your account. If you believe that your account is no longer secure, then you must immediately notify us at support@trydriver.com.</p>

			<h4>5.Mobile Services.</h4>
			<p>The Service is or may be made available through connected mobile devices (“Mobile Services”).  When using the Service through Mobile Services, your Internet Service Provider (“ISP”) or mobile carrier’s normal data, messaging and other fees and rates will apply, and you are solely responsible for paying such fees and rates.  Further, downloading, installing or using certain Mobile Services may be prohibited or restricted by your mobile carrier, and not all Mobile Services may work with all carriers or devices.  You are therefore solely responsible for confirming with your ISP or mobile carrier whether the Mobile Services are available to you and your devices, what restrictions, if any, may be applicable to your use of the Mobile Services, and what the cost will be to you for the Mobile Services.</p>

			<h4>6.Location Based Services.</h4>
			<p>Some of the features of the Service may enable Driver to tailor your experience based on your location (“Location-based Services”).   YOU ACKNOWLEDGE AND AGREE THAT LOCATION DATA MAY BE INACCURATE, AND, WITHOUT LIMIITING ANY OTHER TERMS SET FORTH IN THESE TERMS, AND TO THE FULLEST EXTENT PERMITTED BY LAW, DRIVER DISCLAIMS ANY AND ALL WARRANTIES RELATED TO LOCATION-BASED SERVICES, YOU ASSUME ALL RISKS ARISING FROM OR RELATING TO YOUR USE OF LOCATION-BASED SERVICES, AND WE WILL HAVE NO LIABILITY TO YOU OR ANY THIRD PARTY IN CONNECTION WITH ANY LOCATION DATA.</p>

			<h4>7.Video Recordings.</h4>
			<p>We may provide you with an opportunity to record video footage of activities that occur outside and inside your vehicle and to store such video recordings on the Service for a certain period of time.  The instructions for how to record and store video or audio on your device can be found within the application menu. YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT ALL SUCH RECORDINGS AND YOUR USE, DISCLOSURE OR OTHER EXPLOITATION OF THE SAME COMPLY WITH ALL APPLICABLE LAWS, RULES OR REGULATIONS, INCLUDING ANY RELATING TO THE PRIVACY OR OTHER RIGHTS OF INDIVIDUALS ARISING UNDER THE LAWS OF ANY JURISDICTION, AND WE WILL HAVE NO LIABILITY TO YOU OR ANY OTHER PARTY IN CONNECTION WITH THE SAME.</p>

			<h4>8.General Payment Terms.</h4>
			<p>Certain features of the Service may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. Dollars and are non-refundable.</p>

			<h4>8.1Price.</h4>
			<p>Driver reserves the right to determine pricing for the Service. Driver will make reasonable efforts to keep pricing information published on the website up to date. We encourage you to check our website periodically for current pricing information. Driver may change the fees for any feature of the Service, including additional fees or charges, if Driver gives you advance notice of changes before they apply. Driver, at its sole discretion, may make promotional offers with different features and different pricing to any of Driver’s customers. These promotional offers, unless made to you, will not apply to your offer or these Terms.</p>

			<h4>8.2Authorization.</h4>
			<p>You authorize Driver to charge all sums for the orders that you make and any level of Service you select as described in these Terms or published by Driver, including all applicable taxes, to the payment method specified in your account. If you pay any fees with a credit card, Driver may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.</p>

			<h4>8.1Subscription Service.</h4>
			<p>The Service may include automatically recurring payments for periodic charges (“Subscription Service”). If you activate a Subscription Service, you authorize DRIVER to periodically charge, on a going-forward basis and until cancellation of either the recurring payments or your account, all accrued sums on or before the payment due date for the accrued sums. The “Subscription Billing Date” is the date when you purchase your first subscription to the Service.</p>

			<p>Your account will be charged automatically on the Subscription Billing Date FOR all applicable fees and taxes for the next subscription period. The subscription will continue unless and until you cancel your subscription or we terminate it. You must cancel your subscription before it renews in order to avoid billing of the next periodic Subscription Fee to your account. We will bill the periodic Subscription Fee to the payment method you provide to us during registration (or to a different payment method if you change your payment information).</p>

			<p>You may cancel the Subscription Service by contacting us at: support@trydriver.com.</p>

			<h4>8.2Delinquent Accounts.</h4>
			<p>Driver may suspend or terminate access to the Service, including fee-based portions of the Service, for any account for which any amount is due but unpaid. In addition to the amount due for the Service, a delinquent account will be charged with fees or charges that are incidental to any chargeback or collection of any unpaid amount, including collection fees.</p>

			<h4>9.Licenses</h4>
			<h4>9.1Limited License.</h4>
			<p>Subject to your complete and ongoing compliance with these Terms, Driver grants you, solely for your personal use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to: (a) install and use one object code copy of any mobile application associated with the Service obtained from a legitimate marketplace (whether installed by you or pre-installed on your mobile device by the device manufacturer) on a mobile device that you own or control; and (b) access and use the Service.</p>

			<h4>9.2License Restrictions.</h4>
			<p>Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, you may not use it.</p>

			<h4>9.3Feedback.</h4>
			<p>If you choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Service (“Feedback”), then you hereby grant Driver an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose, including to improve the Service and create other products and services.</p>

			<h4>10.Ownership; Proprietary Rights.</h4>
			<p>The Service is owned and operated by Driver. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Service (“Materials”) provided by Driver are protected by intellectual property and other laws. All Materials included in the Service are the property of Driver or its third party licensors. Except as expressly authorized by Driver, you may not make use of the Materials. Driver reserves all rights to the Materials not granted expressly in these Terms.</p>

			<h4>11.Third Party Terms</h4>
			<h4>11.1Third Party Services and Offers.</h4>
			<p>Driver may provide tools through the Service that enable you to import and export information, including User Content, to third party services, including through features that allow you to link your account on Driver with an account on the third party service, such as Facebook or Google or allow you to take advantage of certain offers from our third party partners including offers for roadside assistance. By using one of these tools, you agree that Driver may transfer that information to the applicable third party service. Third party services are not under Driver’s control, and, to the fullest extent permitted by law, Driver is not responsible for any third party service’s use of your exported information. Your interactions and business dealings with third party services providers, including products or services offered by such third parties, are solely between you and the third party. You should review all of the relevant terms and conditions associated with the third party services, including any privacy policies and terms of service.  If you enter into any transactions with any third party services featured on the Service, Driver may be entitled to a commission fee in connection with such transaction.</p>

			<h4>11.2Third Party Software.</h4>
			<p>The Service may include or incorporate third party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (“Third Party Components”). Although the Service is provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third Party Components under the applicable third party licenses or to limit your use of Third Party Components under those third party licenses.</p>

			<h4>12.User Content</h4>
			<h4>12.1User Content Generally.</h4>
			<p>Certain features of the Service may permit users to upload or transmit content to the Service, including messages, reviews, photos, video recordings, images, folders, data, text, and other types of works (“User Content”). You retain any copyright and other proprietary rights that you may hold in such User Content.</p>

			<h4>12.2Limited License Grant to Driver.</h4>
			<p>By providing User Content to or via the Service, you grant Driver a worldwide, non-exclusive, irrevocable, perpetual, irrevocable, transerable, sublicensable (through multiple-tiers) royalty-free, fully paid right and license to host, store, transfer, display, perform, reproduce, modify for the purpose of formatting for display, and distribute your User Content, in whole or in part, in any media formats and through any media channels now known or hereafter developed.</p>

			<h4>12.3Limited License Grant to Other Users.</h4>
			<p>By providing User Content to or via the Service to other users of the Service as permitted by the functionalities of the Service, you grant those users a non-exclusive license to access and use that User Content as permitted by these Terms and the functionality of the Service.</p>

			<h4>12.4User Content Representations and Warranties.</h4>
			<p>Driver disclaims any and all liability in connection with User Content. You are solely responsible for your User Content and the consequences of providing User Content via the Service. By providing User Content via the Service, you affirm, represent, and warrant that:</p>

			<p>a.you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize Driver and users of the Service to use and distribute your User Content as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by Driver, the Service, and these Terms;</p>

			<p>b.you will expressly inform each person who may be depicted to any extent in any video or other content that you generate using the Service that such content will be recorded or created, you will obtain any necessary consents from each such passenger to so depict that person in any such content or video, and you will provide us with evidence of such consents promptly upon request;</p>

			<p>c.your User Content, and the use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause Driver to violate any law or regulation; and</p>

			<p>d.your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</p>

			<h4>12.5User Content Disclaimer.</h4>
			<p>WE ARE UNDER NO OBLIGATION TO EDIT OR CONTROL USER CONTENT THAT YOU OR OTHER USERS POST OR PUBLISH, AND WILL NOT BE IN ANY WAY RESPONSIBLE OR LIABLE FOR USER CONTENT. DRIVER MAY, HOWEVER, AT ANY TIME AND WITHOUT PRIOR NOTICE, SCREEN, REMOVE, EDIT, OR BLOCK ANY USER CONTENT THAT IN OUR SOLE JUDGMENT VIOLATES THESE TERMS OR IS OTHERWISE OBJECTIONABLE. YOU UNDERSTAND THAT WHEN USING THE SERVICE YOU WILL BE EXPOSED TO USER CONTENT FROM A VARIETY OF SOURCES AND ACKNOWLEDGE THAT USER CONTENT MAY BE INACCURATE, OFFENSIVE, INDECENT, OR OBJECTIONABLE. YOU AGREE TO WAIVE, AND DO WAIVE, ANY LEGAL OR EQUITABLE RIGHT OR REMEDY YOU HAVE OR MAY HAVE AGAINST DRIVER WITH RESPECT TO USER CONTENT. IF NOTIFIED BY A USER OR CONTENT OWNER THAT USER CONTENT ALLEGEDLY DOES NOT CONFORM TO THESE TERMS, WE MAY INVESTIGATE THE ALLEGATION AND DETERMINE IN OUR SOLE DISCRETION WHETHER TO REMOVE THE USER CONTENT, WHICH WE RESERVE THE RIGHT TO DO AT ANY TIME AND WITHOUT NOTICE. FOR CLARITY, DRIVER DOES NOT PERMIT COPYRIGHT-INFRINGING ACTIVITIES ON THE SERVICE.</p>

			<h4>12.6Monitoring Content.</h4>
			<p>DRIVER DOES NOT CONTROL AND DOES NOT HAVE ANY OBLIGATION TO MONITOR: (A) USER CONTENT; (B) ANY CONTENT MADE AVAILABLE BY THIRD PARTIES; OR (C) THE USE OF THE SERVICE BY ITS USERS. YOU ACKNOWLEDGE AND AGREE THAT DRIVER RESERVES THE RIGHT TO, AND MAY FROM TIME TO TIME, MONITOR ANY AND ALL INFORMATION TRANSMITTED OR RECEIVED THROUGH THE SERVICE FOR OPERATIONAL AND OTHER PURPOSES. IF AT ANY TIME DRIVER CHOOSES TO MONITOR THE CONTENT, DRIVER STILL ASSUMES NO RESPONSIBILITY OR LIABILITY FOR CONTENT OR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF CONTENT. DURING MONITORING, INFORMATION MAY BE EXAMINED, RECORDED, COPIED, AND USED IN ACCORDANCE WITH OUR PRIVACY POLICY.</p>

			<h4>13.Prohibited Conduct.</h4>
			<p>BY USING THE SERVICE YOU AGREE NOT TO:</p>

			<p>a.use the Service for any illegal purpose or in violation of any local, state, national, or international law;</p>

			<p>b.violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third party intellectual property right or privacy right;</p>

			<p>c.interfere with security-related features of the Service, including by: (i) disabling or circumventing features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the Service except to the extent that the activity is expressly permitted by applicable law;</p>

			<p>d.interfere with the operation of the Service or any user’s enjoyment of the Service, including by: (i) uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer or advertisement to another user of the Service; (iii) collecting personal information about another user or third party without consent; or (iv) interfering with or disrupting any network, equipment, or server connected to or used to provide the Service;</p>

			<p>e.perform any fraudulent activity including impersonating any person or entity, claiming a false affiliation, accessing any other Service account without permission,</p>

			<p>f.sell or otherwise transfer the access granted under these Terms or any Materials or any right or ability to view, access, or use any Materials; or</p>

			<p>g.attempt to do any of the acts described in this Section 13 or assist or permit any person in engaging in any of the acts described in this Section 13.</p>

			<h4>14.Digital Millennium Copyright Act</h4>
			<h4>14.1DMCA Notification.</h4>
			<p>We comply with the provisions of the Digital Millennium Copyright Act applicable to Internet service providers (17 U.S.C. §512, as amended). If you have an intellectual property rights-related complaint about material posted on the Service, you may contact our Designated Agent at the following address:</p>

			<span>Driver Technologies Inc.</span><br />
			<span>ATTN: Legal Department (Copyright Notification)</span><br />
			<span>85 Delancy St</span><br />

			<span>New York, New York 10012</span><br />
			<span>Email:<a href="mailto:support@trydriver.com">support@trydriver.com</a></span><br />

			<p>Any notice alleging that materials hosted by or distributed through the Service infringe intellectual property rights must include the following information:</p>

			<p>a.an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other right being infringed;</p>

			<p>b.a description of the copyrighted work or other intellectual property that you claim has been infringed;</p>

			<p>c.a description of the material that you claim is infringing and where it is located on the Service;</p>

			<p>d.your address, telephone number, and email address;</p>

			<p>e.a statement by you that you have a good faith belief that the use of the materials on the Service of which you are complaining is not authorized by the copyright owner, its agent, or the law; and</p>

			<p>f.a statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</p>

			<h4>14.2Repeat Infringers.</h4>
			<p>Driver will promptly terminate the accounts of users that are determined by Driver to be repeat infringers. Driver considers a “repeat infringer” to be any user that has uploaded User Content to or through the Service and for whom Driver has received more than two takedown notices compliant with the provisions of 17 U.S.C. § 512 with respect to such User Content. Driver has discretion, however, to terminate the account of any user after receipt of a single notification of claimed infringement or upon Driver’s own determination.</p>

			<h4>15.Modification of these Terms.</h4>
			<p>We reserve the right to change these Terms on a going-forward basis at any time. Please check these Terms periodically for changes. If a change to these Terms materially modifies your rights or obligations, we may require that you accept the modified Terms in order to continue to use the Service. Material modifications are effective upon your acceptance of the modified Terms. Immaterial modifications are effective upon publication. Except as expressly permitted in this Section 15, these Terms may be amended only by a written agreement signed by authorized representatives of the parties to these Terms. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.</p>

			<h4>16.Term, Termination and Modification of the Service</h4>
			<h4>16.1Term.</h4>
			<p>These Terms are effective beginning when you accept the Terms or first download, install, access, or use the Service, and ending when terminated as described in Section 16.2.</p>

			<h4>16.2Termination.</h4>
			<p>If you violate any provision of these Terms, your authorization to access the Service and these Terms automatically terminates. In addition, Driver may, at its sole discretion, terminate these Terms or your account on the Service, or suspend or terminate your access to the Service, at any time for any reason or no reason, with or without notice. You may terminate your account and these Terms at any time by contacting customer service at support@trydriver.com.</p>

			<h4>16.3Effect of Termination.</h4>
			<p>Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the Service; (b) you will no longer be authorized to access your account or the Service; (c) you must pay Driver any unpaid amount that was due prior to termination; and (d) all payment obligations accrued prior to termination and Sections 1, 4, 5, 9.2, 9.3, 10, 11, 12, 13, 14, 16.3, 17, 18, 19, 20 and 21 will survive.</p>

			<h4>16.4Modification of the Service.</h4>
			<p>Driver reserves the right to modify or discontinue the Service at any time (including by limiting or discontinuing certain features of the Service), temporarily or permanently, without notice to you. Driver will have no liability for any change to the Service or any suspension or termination of your access to or use of the Service.</p>

			<h4>17.Indemnity.</h4>
			<p>To the fullest extent permitted by law, you are responsible for your use of the Service, and you will defend and indemnify Driver and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the “Driver Entities”) from and against every claim brought or threatened by a third party, and any related liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or connected with: (a) your unauthorized use of, or misuse of, the Service; (b) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those claims.</p>

			<h4>18.Disclaimers; No Warranties.</h4>
			<p>WITHOUT LIMITING ANY OTHER TERMS IN THIS DOCUMENT AND TO THE FULLEST EXTENT PERMITTED BY LAW:</p>

			<p>THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. DRIVER DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. Driver DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND Driver DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.</p>

			<p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR Driver ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE Driver ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS.</p>

			<p>WE ARE NOT RESPONSIBLE FOR ANY DAMAGE OF ANY KIND THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE SOLELY AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO ANY PROPERTY (INCLUDING YOUR COMPUTER SYSTEM, VEHICLE OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE), ANY BODILY HARM OR ANY LOSS OF DATA, INCLUDING USER CONTENT, ARISING IN CONNECTION WITH YOUR USE OF THE SERVICE.</p>

			<p>THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. Driver does not disclaim any warranty or other right that Driver is prohibited from disclaiming under applicable law.</p>

			<h4>19.Limitation of Liability</h4>
			<p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE DRIVER ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY DRIVER ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.</p>

			<p>EXCEPT AS PROVIDED IN SECTION 20.4 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE DRIVER ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO DRIVER FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.</p>

			<p>EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 19 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>

			<h4>20.Dispute Resolution and Arbitration</h4>
			<h4>20.1Generally.</h4>
			<p>In the interest of resolving disputes between you and Driver in the most expedient and cost effective manner, and except as described in Section 20.2, you and Driver agree that every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND DRIVER ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>

			<h4>20.2Exceptions.</h4>
			<p>Despite the provisions of Section 20.1, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.</p>

			<h4>20.3Arbitrator.</h4>
			<p>Any arbitration between you and Driver will be settled under the Federal Arbitration Act and administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting Driver. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.</p>

			<h4>20.4Notice of Arbitration; Process.</h4>
			<p>A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (“Notice of Arbitration”). Driver’s address for Notice is: Driver Technologies Inc., 85 Delancey St. New York, New York 10012. The Notice of Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or Driver may commence an arbitration proceeding. All arbitration proceedings between the parties will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the amount of any settlement offer made by you or Driver must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than the last written settlement amount offered by Driver in settlement of the dispute prior to the award, Driver will pay to you the higher of: (i) the amount awarded by the arbitrator; or (ii) $10,000.</p>

			<h4>20.5Fees.</h4>
			<p>If you commence arbitration in accordance with these Terms, Driver will reimburse you for your payment of the filing fee, unless your claim is for more than $10,000, in which case the payment of any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in Kings County, New York, but if the claim is for $10,000 or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse Driver for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.</p>

			<h4>20.6No Class Actions.</h4>
			<p>YOU AND DRIVER AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Driver agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.</p>

			<h4>20.7Modifications to this Arbitration Provision.</h4>
			<p>If Driver makes any future change to this arbitration provision, other than a change to Driver’s address for Notice of Arbitration, you may reject the change by sending us written notice within 30 days of the change to Driver’s address for Notice of Arbitration, in which case your account with Driver will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.</p>

			<h4>20.8Enforceability.</h4>
			<p>If Section 20.6 is found to be unenforceable or if the entirety of this Section 20 is found to be unenforceable, then the entirety of this Section 20 will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section 21.2 will govern any action arising out of or related to these Terms.</p>

			<h4>21.Miscellaneous</h4>
			<h4>21.1General Terms.</h4>
			<p>These Terms, together with the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and Driver regarding your use of the Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms at any time without notice or consent. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of section headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word “including” means “including but not limited to.” If any part of these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.</p>

			<h4>21.2Governing Law.</h4>
			<p>These Terms are governed by the laws of the State of New York without regard to conflict of law principles. You and Driver submit to the personal and exclusive jurisdiction of the state courts and federal courts located within Kings County, New York for resolution of any lawsuit or court proceeding permitted under these Terms. We operate the Service from our offices in New York, and we make no representation that Materials included in the Service are appropriate or available for use in other locations.</p>

			<h4>21.3Privacy Policy.</h4>
			<p>Please read the Driver Privacy Policy <a href="#privacy-policy">https://drivertechnologies.com/terms-and-conditions</a> carefully for information relating to our collection, use, storage, disclosure of your personal information. The Driver Privacy Policy is incorporated by this reference into, and made a part of, these Terms.</p>

			<h4>21.4Additional Terms.</h4>
			<p>Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post on or link to from the Service (the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.</p>

			<h4>21.5Consent to Electronic Communications.</h4>
			<p>By using the Service, you consent to receiving certain electronic communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.</p>

			<h4>21.6Contact Information.</h4>
			<p>The Service is offered by Driver Technologies Inc., located at 85 Delancey St. New York, New York 10012. You may contact us by sending correspondence to that address or by emailing us at support@trydriver.com. You can access a copy of these Terms by clicking here: <a href="/terms-and-conditions">https://drivertechnologies.com/terms-and-conditions</a>.</p>

			<h4>21.7Notice to California Residents.</h4>
			<p>If you are a California resident, under California Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210 in order to resolve a complaint regarding the Service or to receive further information regarding use of the Service.</p>

			<h4>21.8No Support.</h4>
			<p>We are under no obligation to provide support for the Service. In instances where we may offer support, the support will be subject to published policies.</p>

			<h4>21.9International Use.</h4>
			<p>The Service is intended for visitors located within the United States. We make no representation that the Service is appropriate or available for use outside of the United States. Access to the Service from countries or territories or by individuals where such access is illegal is prohibited.</p>

			<h4>21.10Roadside Assistance.</h4>
			<p>For paid subscribers of the Service, Driver offers roadside assistance support through its partner, Honk Roadside Assistance and Towing Service. Coverage includes jump starting dead batteries, flat tire change service, towing (excluding winch), help in car lockouts, and support if you accidentally run out of fuel. The Service covers you one time every three months up to four times per calendar year per listed issue.</p>

			<h4>Notice Regarding Apple.</h4>
			<p>This Section 0 only applies to the extent you are using our mobile application on an iOS device. You acknowledge that these Terms are between you and Driver only, not with Apple Inc. (“Apple”), and Apple is not responsible for the Service or the content thereof. Apple has no obligation to furnish any maintenance and support services with respect to the Service. If the Service fails to conform to any applicable warranty, you may notify Apple and Apple will refund any applicable purchase price for the mobile application to you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Service. Apple is not responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use of the Service, including: (a) product liability claims; (b) any claim that the Service fails to conform to any applicable legal or regulatory requirement; or (c) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement and discharge of any third party claim that the Service and/or your possession and use of the Service infringe a third party’s intellectual property rights. You agree to comply with any applicable third party terms when using the Service. Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary of these Terms. You hereby represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p>

			<h1 id="privacy-policy">Privacy Policy</h1>

			<p className="bold">Effective Date: May 2022</p>

			<p>Driver Technologies, Inc. and its affiliates (“Driver,” “we,” “our,” and/or “us”) value the privacy of the individuals who use our website, application, and related services. This privacy policy (the “Privacy Policy”) explains how we collect, use and share information from users of our Services (“Users,” “you,” and/or “your”) to enhance your driving experience. By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our Terms of Use: https://drivertechnologies.com/terms-and-conditions/</p>

			<h4>Information We Collect</h4>
			<p>We may collect a variety of information from or about you or your devices from various sources, as described below.</p>

			<h4>Information You Provide to Us.</h4>

			<h4>Registration and Profile Information.</h4>
			<p>As you use our Services, we may ask for information such as your name, email address, phone number, profile photo, the type of driver you are (e.g., commercial, rideshare, individual), where you like to drive (e.g., work, school, home), the type of vehicle you drive, and your preferences (e.g., your music, temperature, and language preferences). If you sign up for our Services using a social media account, such as a Facebook or Google account, we may also receive information from those social media services, such as your name, email address, and profile photo.</p>

			<h4>Video and Audio.</h4>
			<p>We request access to your camera and microphone and collect video and audio recordings that you make through our Services.</p>

			<h4>Payment Information.</h4>
			<p>If you make a purchase through our Services (for example, if you purchase a subscription to our premium services), your payment-related information, such as your credit and/or debit card and other financial information, is collected by our third party payment processor on our behalf.</p>

			<h4>Communications.</h4>
			<p>If you contact us directly, we may receive additional information about you. For example, if you email our Driver Support Team, we will receive information such as your name, email address, the contents of your message, any attachments you may send to us, and any other information you choose to provide. If you subscribe to receive our updates, we will collect certain information from you such as your name, email address, company name, and phone number. You can unsubscribe from our promotional emails via the link provided in the emails / by communicating your choices to us at support@drivertechnologies.com. Even if you opt-out of receiving promotional messages from us, you will continue to receive administrative messages from us.</p>

			<h4>Referrals.</h4>
			<p>If you choose to refer a friend to our Services, we may ask for information such as your email address and your friend’s email address.</p>

			<h4>Information We Collect When You Use Our Services.</h4>
			<h4>Location Information.</h4>
			<p>We and third party partners receive your device’s precise location information when you use our Services. We and third party partners may directly collect your precise location when our Services are running in the foreground or background. You can prevent your device from sharing precise location information at any time through your device’s operating system settings. However, location is core to our Services and without it, you may not be able to successfully use our Services.</p>

			<h4>Mobile Sensor Data.</h4>
			<p>We collect mobile sensor data from your device when you use our Services, and if you have provided permission in the background as well, such as speed, direction, movement, height, acceleration, deceleration, braking behavior, and cornering, to, for example, improve accuracy and analyze your driving behavior.</p>

			<h4>Information from Cookies and Similar Technologies.</h4>
			<p>We and third party partners collect information using cookies, pixel tags, or similar technologies. Our third party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services.</p>

			<p>Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.</p>

			<h4>Device Information.</h4>
			<p>We receive information about the device and software you use to access our Services, including internet protocol (IP) address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens.</p>

			<h4>Usage Information.</h4>
			<p>To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, like the pages or other content you view and interact with, the searches you conduct, the purchases you make, and the dates and times of your visits.</p>

			<h4>How We Use the Information We Collect</h4>
			<ul>
				<li>To provide, maintain, improve, and enhance our Services and those of our vendors and service providers;</li>

				<li>To personalize your experience on our Services such as by providing tailored content and recommendations;</li>

				<li>To understand and analyze how you use our Services and develop new products, services, features, and functionality;</li>

				<li>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</li>

				<li>To send you text messages and push notifications;</li>

				<li>To facilitate transactions and payments;</li>

				<li>To find a prevent fraud, and respond to trust and safety issues that may arise;</li>

				<li>For compliance purposes, including enforcing our Terms of Use or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</li>

				<li>For other purposes for which we provide specific notice at the time the information is collected.</li>
			</ul>

			<h4>How We Share the Information We Collect</h4>
			<h4>Vendors and Service Providers.</h4>
			<p>We may share non-contact-related information we receive with vendors and service providers retained in connection with the provision of our Services.</p>

			<h4>Road, Safety and Risk Partners</h4>
			<p>We may share non-contact-related, anonymized information we receive in order to better understand roads, road safety and road risk alongside our industry partners.</p>

			<h4>Analytics Partners.</h4>
			<p>We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn about Google’s practices by going to https://www.google.com/policies/privacy/partners/, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.</p>

			<h4>As Required by Law and Similar Disclosures.</h4>
			<p>We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights, property, or safety.</p>

			<h4>Merger, Sale, or Other Asset Transfers.</h4>
			<p>We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets.</p>

			<h4>Consent.</h4>
			<p>We may also disclose your information with your permission.</p>

			<h4>Third Parties</h4>
			<p>Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</p>

			<h4>Security</h4>
			<p>We make reasonable efforts to protect your information by using safeguards designed to improve the security of the information we maintain. However, as our Services are hosted electronically, we can make no guarantees as to the security or privacy of your information.</p>

			<h4>Children</h4>
			<p>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services is directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at support@drivertechnologies.com.</p>

			<h4>International Transfers of Data</h4>
			<p>Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with the storage and processing of data, fulfilling your requests, and operating the Services. By providing any information, including personal information, on or to the Services, you consent to such transfer, storage, and processing.</p>

			<h4>Changes to the Privacy Policy</h4>
			<p>We will post any adjustments to the Privacy Policy at: https://drivertechnologies.com/terms-and-conditions, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through the Services, we will notify you through the Services, by email, or other communication.</p>

			<h4>Contact Information</h4>
			<p>If you have any questions, comments, or concerns about our Privacy Policy, please email us at support@drivertechnologies.com or write to us at Driver Technologies Inc, 85 Delancey St. New York, NY 10002.</p>
		</section>
	);
};

export default TermsAndConditionsEN;

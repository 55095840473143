import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const FORM_ID = process.env.GATSBY_FORM_ID;

const MailingList = () => {
	const { t } = useTranslation(null, { keyPrefix: 'mailing-list' });

	const [email, setEmail] = useState('');
	const [formStatus, setFormStatus] = useState(null);
	const [isAllow, setIsAllow] = useState(false);

	const handleEmailChange = useCallback(({ target }) => {
		setEmail(target.value);
	}, []);

	const handlePermissionChange = useCallback(() => {
		setIsAllow(!isAllow);
	}, [isAllow]);

	const handleSubmit = () => {
		if (!isAllow) return;

		fetch(`https://track.customer.io/api/v1/forms/${FORM_ID}/submit`, {
			method: 'POST',
			headers: {
				'Authorization': `Basic ${btoa(process.env.GATSBY_CUSTOMER_IO_CREDENTIALS)}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				data: {
					id: email,
					email: email,
				},
			}),
		}).then(resp => {
			setFormStatus(resp.status);
		});
	};

	const submitMessage = useMemo(() => {
		if (!formStatus) return null;

		if (formStatus === 204) {
			return (
				<p>{t('thank-you')}</p>
			);
		} else {
			return (
				<p>{t('oops')}</p>
			);
		}

	}, [formStatus, t]);

	return (
		<section id="mailing-list-subscribe">
			<div>
				<h5 className="dark bold">{t('lets-move-the-world')}</h5>
				{formStatus === null ? (
					<div className="input-wrapper">
						<input
							type="email"
							placeholder={t('email-address')}
							value={email}
							onChange={handleEmailChange}
						/>
						<button onClick={handleSubmit}>{t('submit')}</button>
					</div>
				) : submitMessage}

				<div className="checkbox-wrapper">
					<input
						id="mailing-list-confirm"
						type="checkbox"
						checked={isAllow}
						onChange={handlePermissionChange}
					/>
					<label htmlFor="mailing-list-confirm">{t('i-agree-to-driver')}</label>
				</div>
			</div>
		</section>
	);
};

export default MailingList;

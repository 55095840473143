import React from 'react';
import { useTranslation } from 'react-i18next';

const CareerHero = () => {
	const { t } = useTranslation();

	return (
		<div className="career-hero">
			<div className="hero-overlay" />
			<div className="wrap">
				<h1 className="hero-title bold">{t('careers.hero.title')}</h1>
			</div>
		</div>
	);
};

export default CareerHero;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Marble, Stable } from '../components';
import { STABLE, MARBLE } from '../constants';
import { InCarHomeScreen, InCarHomeScreenDesktop } from '../images';
import { DESKTOP_MEDIA } from '../constants';

const PartnerMiddle = ({ organization }) => {
	const [isDesktop, setIsDesktop] = useState(false);

	const { t } = useTranslation();

	const isWindowLoaded = !(typeof window === 'undefined' || !window.document);

	useEffect(() => {
		if (!isWindowLoaded) return;

		const matchMedia = window.matchMedia(DESKTOP_MEDIA);

		setIsDesktop(matchMedia.matches);

		matchMedia.addListener(({ matches }) => {
			setIsDesktop(matches);
		});
	}, [isWindowLoaded]);

	const renderOrg = organization => {
		switch (organization) {
		case MARBLE:
			return (<Marble />);
		case STABLE:
			return (<Stable />);
		default:
			return (
				<div id="context-container">
					<p className="heading semibold">
						{t('partner.as-part-of-our-partnership')}
					</p>
					<p className="subheading">
						{t('partner.driver-the-one')}
					</p>
				</div>
			);
		};
	};

	return (
		<div className="middle">
			{renderOrg(organization)}
			<div className="img-container">
				<img src={!isDesktop ? InCarHomeScreen : InCarHomeScreenDesktop} alt="Mobile home screen" />
			</div>
		</div>
	);
};

export default PartnerMiddle;

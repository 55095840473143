import React from 'react';
import { CardContainer } from '../components/stable-flow';
import { useTranslation } from 'react-i18next';

const PartnerRegister = ({ organization, jotId, jotUrl }) => {
	const { t } = useTranslation();

	return (
		<div className="partner-register" id="register">
			<div className="divider"></div>
			<h1>{t('partner-register.get-started-today')}</h1>
			<p>{t('partner-register.get-started-by')}</p>
			<CardContainer jotId={jotId} jotUrl={jotUrl} organization={organization} />
		</div>
	);
};

export default PartnerRegister;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRModal from '../components/qr-modal';
import appStore from '../images/app-store.svg';
import googlePlay from '../images/google-play.svg';

const AppStoreButtons = () => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const trackClick = osType => {
		window.analytics.track('Download Link Clicked', {
			os: osType,
		});
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const trackClickToggle = osType => {
		if (!isOpen) {
			trackClick(osType);
		}
		
		toggle();
	};

	return (
		<>
			<div className="app-store-buttons mobile">
				<a href="https://trydriver.onelink.me/nCLt/6df40807?af_qr=true">
					<button className="outline-white large app-store" onClick={() => trackClick('iOS')}>
						<img src={appStore} alt={t('common.app-store')} />
					</button>
				</a>

				<a href="https://trydriver.onelink.me/nCLt/6df40807?af_qr=true">
					<button className="outline-white large google-play" onClick={() => trackClick('Android')}>
						<img src={googlePlay} alt={t('common.google-play')} />
					</button>
				</a>
			</div>

			<div className="app-store-buttons desktop">
				<button className="outline-white large app-store" onClick={() => trackClickToggle('iOS')}>
					<img src={appStore} alt={t('common.app-store')} />
				</button>

				<button className="outline-white large google-play" onClick={() => trackClickToggle('Android')}>
					<img src={googlePlay} alt={t('common.google-play')} />
				</button>
			</div>

			<QRModal isOpen={isOpen} toggle={toggle} />
		</>
	);
};

export default AppStoreButtons;

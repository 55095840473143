import React from 'react';
import JotFormReact from 'jotform-react';

const CardContainer = ({ jotId, jotUrl }) => {
	const config = {
		sandbox: ['allow-top-navigation allow-scripts allow-forms'],
	};

	return (
		<div className="card-container">
			<JotFormReact
				formURL={jotUrl}
				formID={jotId}
				{...config}
			/>
		</div>
	);
};

export default CardContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { IphoneDashMount, StepOne, StepTwo } from '../images';
import { MARBLE, STABLE } from '../constants';

const PartnerWorks = ({ organization }) => {
	const { t } = useTranslation();

	const getDashMountMessage = () => {
		switch (organization) {
		case MARBLE:
			return (<span>{t('marble.get-a-custom')}</span>);
		default:
			return (<span>{t('dash.get-one-free')}</span>);
		};
	};

	return (
		<div className="how-it-works">
			<div className="divider" />
			<h1>{t('dash.how-it-works')}</h1>

			<div className="card-container">
				<div className="card">
					<div className="phone-container">
						<img src={StepOne} alt="step-one" />
					</div>

					<div className="context">
						<span className="title">{t('dash.step-one')} {t('dash.mount')}</span>
						<p>{t('dash.step-one-directions-new')} <span>{t('dash.need-a-mount')}</span> {getDashMountMessage()}</p>

						{organization === STABLE ?
							null : (
								<a href="https://www.amazon.com/DriverTechnologies" target="_blank" rel="noreferrer">
									<button>{t('marble.get-dash-mount')}</button>
								</a>)
						}
					</div>
				</div>

				<div className="card">
					<div className="car-container">
						<img src={StepTwo} alt="step-two" />
					</div>

					<div className="context">
						<span className="title">{t('dash.step-two')} {t('dash.go')}</span>
						<p>{t('dash.step-two-directions')}</p>
					</div>
				</div>
			</div>

			<div className="img-container">
				<img src={IphoneDashMount} alt="iPhone Dash Mount" />
			</div>
		</div>
	);
};

export default PartnerWorks;

import React from 'react';
import { useTranslation } from 'react-i18next';

const Marble = () => {
	const { t } = useTranslation();
	
	return (
		<div>
			<h1>{t('partner.as-part-of')}</h1>
			<p>{t('partner.get-the-1')}</p>
			<p>{t('partner.driver-premium')}</p>
		</div>
	);
};

export default Marble;

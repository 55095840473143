import React from 'react';
import { Link } from 'gatsby';
import { CareerRightArrow } from '../images';

const CareerPostMenu = ({ department, roles }) => {
	return (
		<div className="department-container">
			<h3 className="department-name light">{department}</h3>
			<hr />
			{roles.map(role => (
				<Link key={`role-${role.id}`} to={`/job-details/${role.id}`}>
					<div className="post-container">
						<div className="left-side">
							<p className="title">{role.fields['Position Title']}</p>
							<p className="subtitle">{role.fields['Location']}</p>
						</div>
						<div className="right-side">
							<img src={CareerRightArrow} alt="Link to Post" />
						</div>
					</div>
				</Link>
			))}
		</div>
	);
};

export default CareerPostMenu;

import React from 'react';
import { useTranslation } from 'react-i18next';

const CareerContactUs = () => {
	const { t } = useTranslation();

	return (
		<div className="contact-us-container">
			<div className="text-container">
				<h1 className="title bold">{t('careers.contact.not-finding')}</h1>
				<p className="content">{t('careers.contact.get-in-touch')}</p>

				<div className="btn-wrap">
					<a href="https://airtable.com/shrnsyvS2nw8SZJNM" target="_blank" rel="noreferrer">
						<button className="btn semibold">{t('careers.contact.join-talent-network')}</button>
					</a>

					<a href="https://www.linkedin.com/company/drivertechnologiesinc/" target="_blank" rel="noreferrer">
						<button className="btn semibold">{t('careers.contact.connect-on-linkedin')}</button>
					</a>
				</div>
			</div>
		</div>
	);
};

export default CareerContactUs;

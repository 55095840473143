import React from 'react';
import { CollapseMenu } from '../components';
import { MARBLE } from '../constants';
import { DefaultFeatureList, MarbleFeatureList } from '../utils/feature';

const PartnerFeatures = ({ isOpen, setIsOpen, organization }) => {
	const featureList = () => {
		let featureArray;

		switch (organization) {
		case MARBLE:
			featureArray = MarbleFeatureList;

			break;
		default:
			featureArray = DefaultFeatureList;
		}

		return featureArray;
	};

	return (
		<div className="features">
			<div className="features-wrap">
				<h1>Driver Features</h1>
				{featureList().map((feature, idx) => {
					const dropDownId = `feature-${idx}`;

					return (
						<CollapseMenu
							key={dropDownId}
							{...feature}
							organization={organization}
							id={dropDownId}
							className="card"
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default PartnerFeatures;

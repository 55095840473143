import React, { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { withTrans } from '/src/i18n/withTrans';

const COOKIE_OPTIONS = {
	path: '/',
	maxAge: 365 * 24 * 60 * 60, // year
};

const GDPRContainer = () => {
	const { t } = useTranslation(null, { keyPrefix: 'cookie-banner' });

	const [cookies, setCookie] = useCookies(['gatsby-gdpr-segment']);

	const [isOpen, setIsOpen] = useState(!cookies['gatsby-gdpr-segment']);

	const handleAccept = useCallback(() => {
		setCookie('gatsby-gdpr-segment', true, COOKIE_OPTIONS);

		setIsOpen(false);
	}, [setCookie]);

	const handleDeny = useCallback(() => {
		setCookie('gatsby-gdpr-segment', false, COOKIE_OPTIONS);

		setIsOpen(false);
	}, [setCookie]);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	useEffect(() => {
		if (cookies['gatsby-gdpr-segment'] === 'true') {
			window.analytics.load(process.env.GATSBY_SEGMENT_JS_PRD_KEY);
		}
	}, [cookies]);

	if (!isOpen) return null;

	return (
		<div className="gdpr-container">
			<div className="content">
				<button className="semibold" onClick={handleClose}>&times;</button>

				<p>{t('this-website-uses-cookies')}</p>

				<div className="btn-wrapper">
					<button className="semibold" onClick={handleDeny}>{t('deny')}</button>
					<button className="semibold" onClick={handleAccept}>{t('accept')}</button>
				</div>
			</div>
		</div>
	);
};

export default withTrans(GDPRContainer);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import JotForReact from 'jotform-react';
import {
	PageOne,
	PageHeader,
	PageFooter,
	Seo,
} from '../components';
import {
	PartnerBanner,
	PartnerMiddle,
	PartnerWorks,
	PartnerFeatures,
} from '../screens';
import { EMBARK } from '../constants';
import 'regenerator-runtime';

const SignUpForm = ({
	error,
	setIsError,
	organization,
	handleFormSubmit,
	jotURL,
	jotID,
}) => {
	const [disable, setDisable] = useState(true);
	const [id, setId] = useState(null);

	const isWindowLoaded = !(typeof window === 'undefined' || !window.document);

	useEffect(() => {
		if (!isWindowLoaded) return;

		const params = new URLSearchParams(window.location.search);
		const id = params.get('id');

		if (!id) {
			setIsError(true);

			return;
		}

		setId(id);
	}, [isWindowLoaded, setIsError]);

	const toggleDisable = () => {
		setDisable((curr) => !curr);
	};

	const handleRedirect = () => {
		if (!id) {
			setIsError(true);

			return;
		}
		
		const link = `https://trydriver.onelink.me/nCLt/${id}?af_qr=true`;

		window.analytics.track('Driver Premium Sign Up 01-Install', {
			partner: organization,
		});

		window.open(link);
	};

	const containerClasses = classNames({ none: error }, 'form-container');

	if (organization === EMBARK) {
		return (
			<div className={containerClasses} id="sign-up-form">
				<div className="jot-container">
					<JotForReact
						formURL={`${jotURL}${id}`}
						formID={jotID}
						onSubmit={handleFormSubmit}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={containerClasses} id="sign-up-form">
			{disable ? (
				<PageOne
					disable={disable}
					toggleDisable={toggleDisable}
					handleRedirect={handleRedirect}
				/>
			) : (
				<div className="jot-container">
					<JotForReact
						formURL={`${jotURL}${id}`}
						formID={jotID}
						onSubmit={handleFormSubmit}
					/>
				</div>
			)}
		</div>
	);
};

const Partner = ({ organization, icon, jotID, jotURL }) => {
	const { t } = useTranslation();

	const [isError, setIsError] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleSignUpButtonClick = () => {
		document.getElementById('sign-up-form').scrollIntoView();
	};

	const handleFormSubmit = () => {
		window.analytics.track('Driver Premium Sign Up 04-Complete', {
			partner: organization,
		});
	};

	return (
		<div className="partner">
			<Seo title={t('partner.title')} />

			<PageHeader />
			<PartnerBanner
				icon={icon}
				handleSignUpButtonClick={handleSignUpButtonClick}
				error={isError}
			/>
			<PartnerMiddle organization={organization} />
			<PartnerWorks />
			<PartnerFeatures
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>

			<div className="form-outer-wrap">
				{!!isError ? (
					<p className="messages" id="messages">{t('partner.messages.error')}</p>
				) : (
					<SignUpForm
						setIsError={setIsError}
						handleFormSubmit={handleFormSubmit}
						jotID={jotID}
						jotURL={jotURL}
						organization={organization}
					/>
				)}
			</div>
			<PageFooter />
		</div>
	);
};

export default Partner;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CollapseMenu } from '../components';

const DigQuestions = () => {
	const { t } = useTranslation();

	const FAQ = [
		{
			heading: t('dig.questions.q1'),
			context: t('dig.questions.a1'),
		},
		{
			heading: t('dig.questions.q2'),
			context: t('dig.questions.a2'),
		},
		{
			heading: t('dig.questions.q3'),
			context: t('dig.questions.a3'),
		},
		{
			heading: t('dig.questions.q4'),
			context: t('dig.questions.a4'),
		},
		{
			heading: t('dig.questions.q5'),
			context: t('dig.questions.a5'),
		},
		{
			heading: t('dig.questions.q6'),
			context: t('dig.questions.a6'),
		},
		{
			heading: t('dig.questions.q7'),
			context: t('dig.questions.a7'),
		},
		{
			heading: t('dig.questions.q8'),
			context: t('dig.questions.a8'),
		},
		{
			heading: t('dig.questions.q9'),
			context: t('dig.questions.a9'),
		},
		{
			heading: t('dig.questions.q10'),
			context: t('dig.questions.a10'),
		},
		{
			heading: t('dig.questions.q11'),
			context: t('dig.questions.a11'),
		},
		{
			heading: t('dig.questions.q12'),
			context: t('dig.questions.a12'),
		},
		{
			heading: t('dig.questions.q13'),
			context: t('dig.questions.a13'),
		},
		{
			heading: t('dig.questions.q14'),
			context: t('dig.questions.a14'),
		},
	];

	return (
		<div className="dig-program">
			<h1>{t('dig.questions.title')}</h1>

			{FAQ.map((element, idx) => {
				return (
					<CollapseMenu
						key={`dig-faq-${idx}`}
						className="card"
						{...element}
						id={idx}
					/>
				);
			})}
		</div>
	);
};

export default DigQuestions;

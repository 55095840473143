import React from 'react';

const Arrow = () => {
	return (
		<svg
			className="ic-arrow"
			viewBox="0 0 21 18"
			width="21"
			height="18"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M12.9223 0.956724L20.0795 8.11396C20.3613 8.3913 20.5 8.75811 20.5 9.12492C20.5 9.49173 20.3602 9.85764 20.0806 10.1368L12.9234 17.294C12.3642 17.8532 11.4584 17.8532 10.8992 17.294C10.3401 16.7349 10.3401 15.829 10.8992 15.2699L15.6152 10.5564L1.89119 10.5564C1.09987 10.5564 0.5 9.91535 0.5 9.16518C0.5 8.41501 1.09987 7.69347 1.89119 7.69347L15.6152 7.69347L10.9004 2.97864C10.3412 2.41948 10.3412 1.51365 10.9004 0.954488C11.4595 0.395328 12.3631 0.397565 12.9223 0.956724Z" />
		</svg>
	);
};

export default Arrow;

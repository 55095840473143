import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
	.use(LanguageDetector)
	.init({
		fallbackLng: 'en',
		resources: {
			// 'zh-TW': {
			// 	translations: require('../locales/tw/translations.json')
			// },
			en: {
				translations: require('../locales/en/translations.json'),
			},
			es: {
				translations: require('../locales/es/translations.json'),
			},
			pt: {
				translations: require('../locales/pt/translations.json'),
			},
			'zh-CN': {
				translations: require('../locales/zh-Hans-CN/translations.json'),
			},
		},
		ns: ['translations'],
		defaultNS: 'translations',
		returnObjects: true,
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
		detection: {
			order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
		},
	});

i18next.languages = ['en', 'es', 'pt', 'zh-CN'];

export default i18next;

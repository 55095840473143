import React from 'react';
import { useTranslation } from 'react-i18next';

const Stable = () => {
	const { t } = useTranslation();

	return (
		<div>
			<p className="heading semibold">{t('stable.as-part-of')}</p>
			<p className="subheading">{t('stable.driver-is-the')}</p>
		</div>
	);
};

export default Stable;

import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import imgQR from '../images/download-qr.png';

const QRModal = ({ isOpen, toggle }) => {
	const { t } = useTranslation();

	const classes = classNames({
		open: isOpen,
	}, 'modal-container');

	return (
		<div className={classes} onClick={toggle}>
			<div className="modal">
				<div className="btn-close">&times;</div>

				<img
					src={imgQR}
					alt={t('common.download')}
					height={300}
					width={300}
				/>
			</div>
		</div>
	);
};

QRModal.defaultProps = {
	isOpen: false,
	toggle: () => {},
};

export default QRModal;

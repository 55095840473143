import React, { useState } from 'react';
import { STABLE } from '../constants';
import classNames from 'classnames';

const CollapseMenu = ({ id, heading, context, icon, className, linkHeading, organization }) => {
	const [open, setOpen] = useState(false);
	// console.log(linkHeading);

	const trackClickToggle = name => {
		if (!open) {
			window.analytics.track('Feature Details Viewed', {
				title: name,
			});
		}

		setOpen(!open);
	};

	const highlightClass = classNames({ focus: open });
	const activeClass = classNames({ active: open }, 'line1');
	const hideClass = classNames({ hide: open }, 'line2');
	const dropMenuClass = classNames({ description: open, close: !open });

	return (
		<div className={className} id={id}>
			<div className="heading">
				<div className="img-text-wrap">
					<div className="img-container">
						<img src={icon} alt={icon} />
					</div>
					<h1 className={highlightClass}>{heading}</h1>
				</div>
				<div className="btn" onClick={() => trackClickToggle(heading)}>
					<div className={activeClass} />
					<div className={hideClass} />
				</div>
			</div>

			<div className={dropMenuClass}>
				{organization === STABLE && linkHeading ? (
					<a href="https://drivertechnologies.com/terms-and-conditions#privacy-policy">{linkHeading}</a>
				) : (
					<p>{context}</p>
				)}
			</div>
		</div>
	);
};

export default CollapseMenu;

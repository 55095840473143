/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const TermsAndConditionsCH = () => {
	return (
		<section>
			<p>
			欢迎您使用【佐驾】<span className="bold">APP</span> 软件（以下称 本软件 或 许可软件 ），在使用前，
			请您仔细阅读本协议的全部内容（特别是以粗体及/或下划线标注的内容）。
			如果您对本协议的条款有疑问的，请通过相关业务部门进行询问，我们将向您
			解释条款内容。如果您不同意本协议的任何内容，或者无法准确理解我们对条
			款的解释，请不要进行后续操作。
			</p>

			<p>
			除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及
			相关服务。您的下载、安装、使用、获取帐号、登录等行为即视为您已阅读并
			同意上述协议的约束。如果您在页面点击确认或以我们认可的其他方式确认本
			协议，即表示您已充分阅读、理解并同意本协议。
			</p>

			<p>
			本软件用户服务及许可协议（以下称"本协议"）由您与上海肖衡科技有限公司
			（以下称 肖衡 ）共同签署。
			</p>

			<p>
			本协议内容包括协议正文、附件以及所有肖衡已经发布的或未来可能发布的各
			类业务规范、活动规则、通知、公告等（以下统称为 规则 ），包括但不限于：
			《服务协议》、《隐私政策》等内容。所有规则为协议不可分割的组成部分，
			与协议正文具有同等法律效力。
			</p>

			<p>
			肖衡有权根据需要，不时制定、修改本协议和/或各类规则。如有变更，肖衡
			将以网站公示的方式进行公告，不再单独通知。变更后的协议和规则一经公布
			即自动生效，成为本协议的一部分。如不同意相关变更，您应当立即停止使用
			肖衡的服务；如您继续使用该服务，则视为对修改后的协议和规则不持异议并
			同意遵守。
			</p>

			<p>
			一、定义
			</p>

			<p>
			1.1.本软件/许可软件：是指由肖衡开发的，供您从下载平台下载，并仅限在
			相应系统移动终端中安装、使用的软件系统，包括但不限于 Android、ios版
			本的软件系统。
			</p>

			<p>
			1.2.服务：由上海肖衡科技有限公司为您提供的服务。您可以通过本软件在移
			动终端使用服务。
			</p>

			<p>
			二、服务内容
			</p>

			<p>
			2.1.肖衡为您提供一款整合人工智能、计算机视觉和数据科学技术，用于改善
			道路安全，并重新定义安全驾驶标准的手机APP。该APP的主要功能有视频
			行车记录，前撞预警、AR导航、车内视频记录等。
			</p>

			<p>
			三、授权范围
			</p>

			<p>
			3.1.肖衡就本软件给予您一项仅限您个人的、不可转让、不可转授权以及非独
			占性的许可，您必须按照本协议及肖衡的其他协议、说明、规则限定的范围和
			方式使用本软件。
			</p>

			<p>
			3.2.由于软件适配平台及终端限制，您理解您仅可在获授权的系统平台及终端
			使用许可软件，如您将许可软件安装在其他终端设备上（包括台式电脑、手提
			电脑、或授权终端外的其他手持移动终端、电视机及机顶盒等），可能会对您
			硬件或软件功能造成损害。
			</p>

			<p>
			3.3.您应该理解许可软件仅可用于非商业目的，您不可为商业运营目的安装、
			使用、运行许可软件。如果需要进行商业性的复制、传播、销售，例如软件预
			装和捆绑，必须提前获得肖衡的书面授权和许可。
			</p>

			<p>
			3.4.肖衡会对许可软件及其相关功能不时进行变更、升级、修改或转移，并会
			在许可软件系统中开发新的功能或其它服务。上述新的功能、软件服务如无独
			立协议的，您仍可基于本协议取得相应功能或服务的授权，您的使用行为视为
			对上述服务或功能新发布的各类规则的同意。
			</p>

			<p>
			四、第三方产品或服务
			</p>

			<p>
			4.1.许可软件可能使用或包含了由第三方提供的软件或服务（以下称该等服
			务），该等服务是为了向您提供便利而设置，并已经取得该第三方的合法授权。
			</p>

			<p>
			4.2.对于本软件推荐您使用的第三方产品或第三方网站，您自行决定选择是否
			使用，但您须谨慎选择。本软件中所包含与其他网站或资源的链接，肖衡对于
			前述网站或资源的内容、隐私政策和活动无权控制、审查或修改，除法律有明
			确规定外，肖衡亦不承担责任。建议您在离开本软件，访问其他网站或资源前
			仔细阅读其服务条款和隐私政策。
			</p>

			<p><span className="bold">4.3.</span>若肖衡使用了第三方提供的服务，
			肖衡仅会出于合法的、正当的、必要的、以及为实现本协议中声明的目的，
			共享给该第三方必要的用户数据，以为您提 供相关服务。
			由于第三方为其软件或服务的提供者，您使用该等服务时，应另
			行与该第三方达成服务协议，支付相应费用并承担可能的风险。您应理解肖衡
			并无权在本协议中授予您使用该等服务的权利，也无权对该等服务提供任何形
			式的保证。您在使用第三方服务之前，请认真阅读第三方的相关协议和政策，
			并在使用过程中遵守相关服务协议。肖衡无法对该等服务提供客户支持，如果
			您需要获取支持，您应直接与该第三方联系。因您使用该等服务引发的任何纠
			纷，您可直接与该第三方协商解决。
			</p>

			<p>
			4.4.本软件服务过程中可能通过短信、电子邮件或电子信息等多种方式向您展
			示包括信息发布、品牌推广服务或其他形式广告等，您在此同意显示肖衡及/
			或第三方的广告和商业资讯。但请您自行对所发布的广告内容进行判断并谨慎
			使用，对于第三方所提供的商品或服务，肖衡不做任何担保与承诺，如产生纠
			纷，与肖衡无关。
			</p>

			<p>
			4.5.您在本软件过程中使用第三方提供的产品或服务时，除遵守本协议约定外，
			还应遵守第三方的用户协议。肖衡和第三方对可能出现的纠纷在法律规定和约
			定的范围内各自承担责任。
			</p>

			<p>
			五、用户使用规范
			</p>

			<p>
			5.1.您应该规范使用许可软件，不得利用本软件从事下列行为：
			</p>

			<p>
			1)从事违反法律法规政策、破坏公序良俗、损害公共利益的行为。
			</p>

			<p>
			2)对许可软件及其中的相关信息擅自进行读取、出租、出借、复制、修改、链
			接、转载、汇编、发表、出版、建立镜像站点，借助许可软件发展与之有关的
			衍生产品、作品、服务、插件、外挂、兼容等。
			</p>

			<p>
			3)通过非由肖衡及其关联公司开发、授权或认可的第三方兼容软件、系统登录
			或使用许可软件，或针对许可软件使用非肖衡及其关联公司开发、授权或认证
			的插件和外挂。
			</p>

			<p>
			4)删除许可软件及其他副本上关于版权的信息、内容。修改、删除或避开上述
			产品中肖衡为保护知识产权而设置的任何技术措施。
			</p>

			<p>
			5)未经肖衡的书面同意，擅自将许可软件出租、出借或再授权给第三方使用。
			</p>

			<p>
			6) 对许可软件及相关服务进行反向工程、反向汇编、编译或者以其他方式尝
			试发现软件的源代码；对许可软件或者许可软件运行过程中释放在终端的任何
			数据及许可软件运行过程中终端与服务器端的交互数据进行复制、修改、挂接
			运行或创作任何衍生作品，包括但不限于通过使用插件、外挂或非经授权的第
			三方工具/服务接入许可软件和相关系统等形式。
			</p>

			<p>
			7)进行任何危害信息网络安全的行为，包括使用许可软件时以任何方式损坏或
			破坏许可软件或使其不能运行或超负荷或干扰第三方对许可软件的使用；未经
			允许进入公众或他人计算机系统并删除、修改、增加存储信息；故意传播恶意
			程序或病毒以及其他破坏、干扰正常网络信息服务的行为。
			</p>

			<p>
			8)利用许可软件发表、传送、传播、储存侵害他人专利权、著作权、商标权、
			肖像权、名誉权或其他任何合法权益的内容，或从事欺诈、盗用他人账户、资
			金等违法犯罪活动。
			</p>

			<p>
			9)通过修改或伪造许可软件运行中的指令、数据、数据包，增加、删减、变动
			许可软件的功能或运行效果，及/或将具有上述用途的软件通过信息网络向公
			众传播或者运营。
			</p>

			<p>
			10)其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一
			致的方式使用许可软件。
			</p>

			<p>
				<span className="bold">5.2.</span>您理解并同意：
			</p>

			<p>
				<span className="bold">1)</span>肖衡会对您是否涉嫌违反上述使用规范做出认定，并根据单方认定结果中止、
			终止对您的使用许可或采取其他依约可采取的限制措施。
			</p>

			<p>
				<span className="bold">2)</span>对于您使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协
			议的信息，肖衡会直接予以删除。
			</p>

			<p>
				<span className="bold">3)</span>对于您违反上述使用规范的行为对肖衡、任意第三方造成损害的，您需要以
			自己的名义独立承担法律责任，并应确保肖衡免于因此产生损失或增加费用。
			</p>

			<p>
				<span className="bold">4)</span>如您违反有关法律或者本协议之规定，使肖衡遭受任何损失，或受到任何第
			三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿因此给肖衡造成的
			损失及（或）发生的费用，包括合理的律师费用、调查取证费用等。
			</p>

			<p>
			六、信息内容规范=
			</p>

			<p>
			6.1. 您可使用本软件及服务上载、传送或分享属于您原创或您有权发表的观
			点看法、数据、文字、信息、用户名、图片、照片、个人信息、音频、视频文
			件、链接等信息内容。您必须保证，您拥有您所发布信息内容的著作权或已获
			得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。
			</p>

			<p>
			您承诺不会利用本软件进行任何违法或不当的活动，包括但不限于下列行为:
			</p>

			<p>
			6.1.1.上载、传送或分享含有下列内容之一的信息：
			</p>

			<p>
			1)反对宪法所确定的基本原则的；
			</p>

			<p>
			2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
			</p>

			<p>
			3)损害国家荣誉和利益的；
			</p>

			<p>
			4)煽动民族仇恨、民族歧视、破坏民族团结的；
			</p>

			<p>
			5)破坏国家宗教政策，宣扬邪教和封建迷信的；
			</p>

			<p>
			6)散布谣言，扰乱社会秩序，破坏社会稳定的；
			</p>

			<p>
			7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
			</p>

			<p>
			8)侮辱或者诽谤他人，侵害他人合法权利的；
			</p>

			<p>
			9)含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、
			猥亵、或其它道德上令人反感的内容；
			</p>

			<p>
			10)含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或
			禁止的其它内容的；
			</p>

			<p>
			6.1.2.提交、发布虚假信息，或冒充、利用他人名义开展相关活动；
			</p>

			<p>
			6.1.3.将涉及他人隐私、个人信息或资料的任何非法内容加以上载、传送或分
			享；
			</p>

			<p>
			6.1.4.将依据任何法律或合约或法定关系（例如雇佣关系或保密合约）知悉但
			无权传送之任何内容加以上载、传送或分享；
			</p>

			<p>
			6.1.5.将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘
			密等知识产权）之内容上载、传送或分享；
			</p>

			<p>
			6.1.6.跟踪或以其它方式骚扰他人, 或通过本软件向好友或其他用户发送大量
			骚扰信息；
			</p>

			<p>
			6.1.7.将任何广告、推广信息、促销资料、 垃圾邮件 、 滥发信件 、 连锁
			信件 、 直销 或其它任何形式的商业化推广或劝诱资料加以上载、传送或分
			享；在供前述目的使用的专用区域或专用功能除外；
			</p>

			<p>
			6.1.8.因本软件并非为某些特定目的而设计，您不可将本软件用于包括但不限
			于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务
			的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，肖衡不
			承担法律责任；
			</p>

			<p>
			6.1.9.进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的
			的行为；
			</p>

			<p>
			6.1.10.出于超出正常好友或用户之间内部或外部信息沟通、交流等目的（包
			括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），通过自己添
			加或诱导他人添加等任何方式使自己与其他用户形成好友关系（好友关系包括
			但不限于单向好友关系和双向好友关系，下同）；
			</p>

			<p>
			6.1.11.从事违反法律法规、社会主义制度、国家利益、公民合法利益、公共
			秩序、社会道德风尚和信息真实性等 七条底线 要求的行为；
			</p>

			<p>
			6.1.12.从事其他违反法律法规规定及本协议约定、侵犯互联网用户合法权益、
			干扰肖衡正常运营或其他不符合本软件规范的行为。
			</p>

			<p>
				<span className="bold">6.2.</span> 您未遵守前款规定或违反本协议或相关服务条款的规定，需对自己的行
			为承担法律责任；由此导致或产生的任何第三方主张的任何索赔、要求或损失，
			包括合理的律师费、调查费等，您应当全额赔偿肖衡及关联公司，并使之免受
			损害。对此，肖衡有权视您的违规行为性质，单方判断、并采取包括但不限于
			删除发布信息内容、暂停使用许可、终止服务、限制使用、追究法律责任等措
			施。同时，肖衡会视司法部门的要求，协助调查。
			</p>

			<p>
			七、隐私政策与数据
			</p>

			<p>
				<span className="bold">7.1.</span>您在使用本软件服务的过程中，可能需要填写一些必要的信息或由本软件
			获取一些必要信息。您确认并同意，肖衡为实现本软件功能和提供服务之需要，
			可保存您的必要信息，并可向您发送与本软件功能和服务相关的信息。肖衡不
			会将您的个人信息转移或披露给任何第三方，除非：
			</p>

			<p>
				<span className="bold">1）</span>相关法律法规或司法机关、行政机关要求；
			</p>

			<p>
				<span className="bold">2）</span>为完成合并、分立、收购、资产转让、业务变更而转移；
			</p>

			<p>
				<span className="bold">3）</span>为提供您要求的服务所必需；或
			</p>

			<p>
				<span className="bold">4）</span>经您允许或依据其他相关协议规则合法地可转移或披露给任何第三方的情
			形。
			</p>

			<p>
			7.2.保护您的个人信息对肖衡很重要。肖衡制定了《隐私政策》对知识产权归
			属及保护、您的信息收集、使用、共享、存储、保护等关系您切身利益的内容
			进行了重要披露。肖衡建议您完整地阅读《隐私政策》，以帮助您更好的保护
			您的个人信息。
			</p>

			<p>
			八、免责和责任限制
			</p>

			<p>
			8.1.除法律法规有明确规定外，肖衡将尽最大努力确保许可软件及其所涉及的
			技术及信息安全、有效、准确、可靠，但受限于现有技术，您理解肖衡不能对
			此进行担保。
			</p>

			<p>
			8.2.您根据自身需求自愿选择使用本软件及相关服务，因使用本软件及相关服
			务所存在的风险和一切后果将完全由您自己承担，肖衡不承担任何责任。
			</p>

			<p>
				<span className="bold">8.3.</span>本软件为您提供的服务是按照现有技术和条件所能达到的现状提供的。肖
			衡会尽最大努力保障服务的连贯性和安全性，但肖衡不能随时预见和防范法律、
			技术以及其他风险，肖衡对此类风险在法律允许的范围内免责，包括但不限于
			不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为
			等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
			</p>

			<p>
			8.4.您理解并同意，对于前款规定的不可抗力和其他第三方原因导致的您的直
			接或间接损失，肖衡不承担责任。
			</p>

			<p>
				<span className="bold">8.5.</span>您因下述任一情况所引起或与此有关的人身伤害或附带的、间接的损害赔
			偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或其它商业损害
			赔偿或损失，需由您自行承担：
			</p>

			<p>
				<span className="bold">1)</span>使用或未能使用许可软件产生的损害及风险；
			</p>

			<p>
				<span className="bold">2)</span>第三方未经批准使用许可软件或更改您的数据；
			</p>

			<p>
				<span className="bold">3)</span>使用许可软件进行的活动所产生的费用及损失；
			</p>

			<p>
				<span className="bold">4)</span>您对许可软件的误解；
			</p>

			<p>
				<span className="bold">5)</span>网络信息或其他用户行为带来的风险；
			</p>

			<p>
				<span className="bold">6)</span>非因肖衡的原因而引起的与许可软件有关的其它损失。
			</p>

			<p>
			8.6.非经肖衡或肖衡授权开发并正式发布的其它任何由许可软件衍生的软件均
			属非法，下载、安装、使用此类软件，可能导致不可预知的风险，由此产生的
			法律责任与纠纷与肖衡无关，肖衡有权中止、终止使用许可和/或其他一切服
			务。
			</p>

			<p>
			8.7.因您使用本软件时，本软件可能会调用第三方系统支持您的使用或访问，
			使用或访问的结果由该第三方提供，肖衡不保证通过第三方系统支持实现的结
			果的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及
			损害，与肖衡无关，肖衡不承担任何责任。
			</p>

			<p>
			8.8.在法律允许的范围内，肖衡对下述任一情形导致的服务中断或受阻不承担
			责任：
			</p>

			<p>
			1）因电信系统或互联网网络故障、手机故障或病毒、信息损坏或丢失、手机
			系统问题，受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
			</p>

			<p>
			2）您或肖衡的电脑软件、系统、硬件和通信线路出现故障；
			</p>

			<p>
			3）您操作不当或您通过非肖衡授权的方式使用本软件；
			</p>

			<p>
			4）程序版本过时、设备的老化和/或其兼容性问题；
			</p>

			<p>
			5）其他肖衡无法控制或合理预见的情形。
			</p>

			<p>
			九、知识产权
			</p>

			<p>
			9.1.肖衡拥有本软件及肖衡为提供服务而使用的其他任何软件的著作权、专利、
			商标、商业秘密以及其他相关的知识产权，包括与许可软件有关的所有文档资
			料信息及其中的知识产权。
			</p>

			<p>
			9.2.许可软件的商标、服务标记、商号等相关标识、logo、域名、品牌和名称
			等属于肖衡及肖衡的关联公司的知识产权及其他合法权利受到相关法律法规的
			保护。未经肖衡明确授权，您不得复制、模仿、使用或发布上述标识，也不得
			修改或删除本软件中体现肖衡及其关联公司的任何标识或身份信息。
			</p>

			<p>
			您在任何情况下都不得实施向他人明示或暗示有权展示、使用、或其他有权处
			理本软件及肖衡相关知识产权的行为。否则，因此给肖衡或他人造成损失的，
			由您承担相关法律责任。
			</p>

			<p>
			9.3.未经肖衡及肖衡的关联公司事先书面同意，您不得为任何营利性或非营利
			性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识
			产权。亦不得进行任何商业性的销售、复制、分发，包括但不限于软件销售、
			预装、捆绑等。
			</p>

			<p>
			十、协议终止和违约责任
			</p>

			<p>
				<span className="bold">10.1.</span>您应按授权范围使用许可软件、尊重软件及软件包含内容的知识产权、
			按法律规定及肖衡相关规范使用本软件、按本协议约定履行义务，如您违反本
			协议，肖衡将终止使用许可。
			</p>

			<p>
			10.2.您对软件的使用有赖于肖衡及关联公司为您提供的配套服务，您违反与
			肖衡或肖衡关联公司的条款、协议、规则、通告等相关规定，而被上述任一网
			站终止提供服务的，可能导致您无法正常使用许可软件，肖衡有权终止使用许
			可。
			</p>

			<p>
			10.3.您理解出于维护平台秩序及保护消费者权益的目的，如果您向肖衡及
			（或）肖衡的关联公司作出任何形式的承诺，且相关公司已确认您违反了该承
			诺并通知肖衡依据您与其相关约定进行处理的，则肖衡可按您的承诺或协议约
			定的方式对您的使用许可及其他肖衡可控制的权益采取限制措施，包括中止或
			终止对您的使用许可。
			</p>

			<p>
			10.4.一旦您违反本协议或与肖衡签订的其他协议的约定，肖衡可通知肖衡关
			联公司，要求其对您的权益采取限制措施，包括要求关联公司中止、终止对您
			提供部分或全部服务，且在其经营或实际控制的网站依法公示您的违约情况。
			</p>

			<p>
			10.5.许可软件由您自下载平台下载取得，您需要遵守下载平台、系统平台、
			终端厂商对您使用许可软件方式与限制的约定，如果上述第三方确认您违反相
			关协议约定需要肖衡处理的，肖衡可能会因第三方要求终止对您的使用许可。
			</p>

			<p>
			10.6.在本使用许可终止时，您应停止对许可软件的使用行为, 并销毁许可软
			件的全部副本。
			</p>

			<p>
			10.7.如您违反本协议规定的条款，给肖衡或其他第三方造成损失，您必须承
			担全部的赔偿责任。如肖衡承担了上述责任，则您同意赔偿肖衡的相关支出和
			损失，包括合理的律师费用等。
			</p>

			<p>
			十一、管辖法律和可分割性
			</p>

			<p>
			11.1.本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法
			律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。
			</p>

			<p>
				<span className="bold">11.2.</span>因本协议产生或与本协议有关的争议，您可与肖衡以友好协商的方式予
			以解决或提交肖衡所在地人民法院予以裁决。
			</p>

			<p>
			11.3.本协议任何条款被有管辖权的人民法院裁定为无效，不应影响其他条款
			或其任何部分的效力，您与肖衡仍应善意履行。
			</p>

			<p>
			十二、通知与送达
			</p>

			<p>
			12.1.您在本软件注册及更新时提供的联系方式（包括您的电子邮件地址、联
			系电话、联系地址等），以及您在注册时生成的会员账号等，将作为您的有效
			联系方式。
			</p>

			<p>
			12.2.肖衡将通过上述联系方式的其中之一或其中若干向您送达各类通知，而
			此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及
			时关注。
			</p>

			<p>
			12.3.肖衡通过上述联系方式向您发出通知，其中以电子的方式发出的书面通
			知，包括但不限于站内公告、手机短信、电子邮件、向您的客户端推送消息等，
			在发送成功后即视为送达；以纸质载体发出的书面通知，按照您提供的联系地
			址、交邮后的第三个自然日即视为送达。
			</p>

			<p>
			12.4. 对于因使用本网站服务引起的任何纠纷，您同意司法机关（包括但不限
			于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送
			达法律文书（包括但不限于诉讼文书），并同意上述送达方式适用于各个司法
			程序阶段。
			</p>

			<p>
			12.5. 您应当保证您所提供的联系方式是准确、有效的，并进行实时更新。如
			果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无
			法送达或未及时送达，由您自行承担由此可能产生的法律后果。
			</p>

			<p>
			十三、其他
			</p>

			<p>
			13.1.肖衡可能根据业务调整而变更向您提供软件服务的主体，变更后的主体
			与您共同履行本协议并向您提供服务，以上变更不会影响您本协议项下的权益。
			发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象
			确定与您履约的主体及争议相对方。
			</p>

			<p>
			13.2.本协议的所有标题仅仅是为了醒目及阅读方便，本身并没有实际涵义，
			不能作为解释本协议涵义的依据。
			</p>

			<p>
			13.3.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，
			本协议的其余条款仍应有效并且有约束力。
			</p>

			<p>
			13.4.肖衡有权随时根据有关法律、法规的变化以及公司经营状况和经营策略
			的调整等修改本协议。修改后的协议会及时公布，并随附于新版本软件。当发
			生有关争议时，以最新的协议文本为准。如果您不同意改动的内容，您可以自
			行卸载或者删除本软件。如果您继续使用本软件，则视为您接受本协议的变动。
			</p>

			<p>
			隐私政策
			</p>

			<p>
			上海肖衡科技有限公司及其关联公司（以下称 我们 、 肖衡 或公司）尊重并
			保护所有使用【佐驾】APP “ ”软件（以下称 本软件 ）服务用户的个人隐私权。
			为了给您提供更准确、个性化的服务，我们会按照本隐私政策的规定使用和披
			露您的个人信息。我们将以高度的勤勉、审慎义务对待这些信息。除本隐私政
			策另有规定外，在未征得您事先许可的情况下，我们不会将这些信息对外披露
			或向第三方提供。肖衡会不时更新本隐私政策。您在同意本软件用户服务及许
			可协议之时，即视为您已经同意本隐私政策全部内容。
			</p>

			<p>
			一、我们如何收集和使用您的个人信息
			</p>

			<p>
			我们仅会出于本政策所述的以下目的及用途，收集和使用您的个人信息（包括
			个人敏感信息）：
			</p>

			<p>
			（一）实现本软件的核心功能
			</p>

			<p>
			核心功能是指支撑本软件正常运行和提供服务的基本功能。若您不同意我们收
			集和使用向您提供服务所必需的个人信息，您将无法使用本软件。
			</p>

			<p>
			我们将收集和使用下述信息：
			</p>

			<p>
			1．注册信息
			</p>

			<p>
			当您在手机上安装本软件后，需要注册或登录用户账号方可使用。当您注册帐
			号时，我们会收集您的手机号码、邮箱、用户名、密码、个人照片，以及您的
			个人偏好（例如您驾驶的车辆类型、经常驾驶的地点、音乐偏好等）；如果您
			使用其他社交媒体账号（例如微信、支付宝等）进行登录，我们也将从这些第
			三方运营商处收集上述信息。
			</p>

			<p>
			收集这些信息是为了帮助您完成本软件的帐号注册，并保护您的帐号安全。
			</p>

			<p>
			2．视频和音频信息
			</p>

			<p>
			当您使用我们的服务时，我们将要求访问您的相机和麦克风，并收集相关视频
			和音频记录。
			</p>

			<p>
			3．地理位置信息
			</p>

			<p>
			当您使用我们的服务时，我们和第三方合作伙伴将直接收集您设备的精确位置
			信息。
			</p>

			<p>
			（二）实现本软件的第三方功能
			</p>

			<p>
			第三方功能指第三方软件和服务提供者提供的功能。我们仅会出于合法的、正
			当的、必要的、特定的、明确的以及为实现本政策中声明的目的，共享给第三
			方必要的用户数据，以为您提供相关服务。同时，当您选择使用第三方功能和
			服务时，需要按照第三方的要求提供您的个人信息。您提供的信息直接上传到
			第三方的服务器，您在使用第三方产品或服务之前，请认真阅读第三方的相关
			协议和政策，并在使用其产品功能的过程中遵守第三方服务协议。
			</p>

			<p>
			我们将从第三方处收集以下信息：
			</p>

			<p>
			1．支付信息
			</p>

			<p>
			如果您通过我们的服务进行付款，例如购买我们的升级服务、跳转到第三方平
			台购买软硬件设备等，在购买的过程中，您需要根据第三方商城的规定提供个
			人信息。上述第三方服务提供商将与我们共享您的支付相关信息，包括但不限
			于您的借记卡/信用卡账号及其他财务信息等。
			</p>

			<p>
			2．其他我们可能从第三方合作运营商处收到的关于您的额外信息，例如位置
			信息等，用以预测与您的驾驶习惯相关的风险等级。
			</p>

			<p>
			（三）手机权限调用
			</p>

			<p>
			本软件在提供服务过程中，会调用您手机的一些设备权限。您可以在设备的设
			置功能中选择关闭部分或全部权限，从而拒绝本软件收集相应的个人信息。在
			不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统
			开发方的说明或指引。
			</p>

			<p>
			请您注意，您开启以下任一权限即代表您授权我们和/或提供该权限功能的第三
			方合作运营商可以收集和使用相关个人信息来为您提供对应服务，您关闭任一
			权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人
			信息，也将停止为您提供相对应的服务。但是，您关闭权限的操作不会影响此
			前基于您的授权所进行的信息收集及使用。
			</p>

			<p>
			1、位置信息权限
			</p>

			<p>
			使用目的：获取位置及定位信息，实现导航功能等。
			</p>

			<p>
			当本软件在您的设备前台或后台运行时，我们和第三方合作伙伴可能直接收
			集您的精确位置。位置信息关系着本软件的核心功能，如果您选择关闭，您
			可能无法使用我们的服务。
			</p>

			<p>
			2、移动传感器权限
			</p>

			<p>
			当您使用我们的服务时，我们从您的设备上通过手机的加速仪、陀螺仪等移
			动传感器收集驾驶行为数据，如速度、方向、移动、高度、加速、减速、制
			动行为和转弯，以便提高服务的准确性以及分析您的驾驶习惯和偏好。
			</p>

			<p>
			3、存储权限
			</p>

			<p>
			使用目的：图片缓存，保存从记录仪上下载的视频和图片，软件更新包下载
			和升级保存等。
			</p>

			<p>
			4、相机、麦克风权限
			</p>

			<p>
			使用目的：录制实时行车视频及音频（可能包括车内），扫描二维码，识别
			身份证、行驶证信息；智能语音命令输入，对讲机模块语音聊天等。
			</p>

			<p>
			5、安装其他应用权限
			</p>

			<p>
			使用目的：软件更新包安装
			</p>

			<p>
			6、通知权限
			</p>

			<p>
			使用目的：肖衡及第三方合作伙伴将以横幅或悬浮窗等形式进行信息发布、数
			据更新、品牌推广、其他形式广告等推送服务。
			</p>

			<p>
			（四）提供安全保障，解决技术故障，提供用户支持
			</p>

			<p>
			1.提供安全保障，解决技术故障，处理您关于设备与服务的提问。为保障账号
			与设备安全，处理您关于设备与服务的提问，不断改进我们的产品、排除并减
			少产品崩溃情况以及为您提供更优质及个性化定制的产品功能及服务，我们将
			通过手机端口收集您手机及硬件设备的以下信息：包括互联网协议（IP）地址、
			网络浏览器类型、操作系统版本、手机运营商和制造商、应用程序安装、设备
			标识符、移动广告标识符和推送通知标记等。用于优化产品功能，这些信息不
			涉及您的个人身份相关信息。
			</p>

			<p>
			2.保障服务的安全性。为提高您使用我们及关联公司、合作伙伴提供服务的安
			全性，保护您或其他公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺
			诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别
			违反法律法规或肖衡相关协议及规则的情况，我们可能使用或整合您的账户信
			息、硬件设备信息、软件使用信息以及我们关联公司、合作伙伴取得您授权或
			依据法律共享的信息，用于进行身份验证、检测及防范安全事件，并依法采取
			必要的记录、审计、分析、处置措施。
			</p>

			<p>
			3.反馈和咨询。当您向我们反馈问题或咨询时，我们将获取您的姓名、电子邮
			件地址、手机号码、您发送的信息内容、您可能发送给我们的任何附件，以及
			您选择提供的任何其他信息。我们会通过分析前段中提到的信息找出问题原因
			并努力解决问题。
			</p>

			<p>
			（五）征得授权同意的例外
			</p>

			<p>
			根据相关法律、法规、国家标准以及行业指南规定，以下情形中处理您的个人
			信息无需征得您的授权同意：
			</p>

			<p>
			1.与国家安全、国防安全、公共安全、公共卫生、重大公共利益有关的；
			</p>

			<p>
			2.与犯罪侦查、起诉、审判和判决执行等有关的；
			</p>

			<p>
			3．出于合规目的，如应法律法规的要求，或任何司法程序或政府机构的要求
			等；
			</p>

			<p>
			4.出于维护个人信息主体或其他公众的生命、财产等重大合法权益的；
			</p>

			<p>
			5.所收集的个人信息是依法或者您主动公开的；
			</p>

			<p>
			6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公
			开等渠道；
			</p>

			<p>
			7.根据您的要求签订和履行合同所必需的；
			</p>

			<p>
			8.用于维护我们的产品或服务的安全稳定运行所必需的，例如发现、处置产品
			或服务的故障；
			</p>

			<p>
			9.学术研究机构基于公共利益开展统计或学术研究所必需，且对外提供学术研
			究结果时，对其中所包含的个人信息进行去标识化处理的；
			</p>

			<p>
			10.法律法规或服务协议规定的其他情形。
			</p>

			<p>
			二、我们如何存储您的个人信息
			</p>

			<p>
			我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境
			内。
			</p>

			<p>
			如我们停止运营肖衡的产品和服务，我们将及时停止继续收集您个人信息的行
			为，将停止运营的通知以逐一送达或公告的形式通知您，并对我们此前存储的
			个人信息进行删除或匿名化处理。
			</p>

			<p>
			三、我们如何共享您的个人信息
			</p>

			<p>
			我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：
			</p>

			<p>
			1.在获取您明确同意的情况下，我们会与其他方共享您的个人信息；
			</p>

			<p>
			2.我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依
			法提出的要求，对外共享您的个人信息；

			</p>

			<p>
			3.在法律法规允许的范围内，为维护肖衡、肖衡的关联方或合作伙伴、您或其
			他社会公众利益、财产或安全免遭损害而有必要共享您的个人信息；
			</p>

			<p>
			4.关联公司间共享：我们仅共享必要的个人信息且不超出您同意的使用目的，
			关联方对您信息的处理受本政策的约束。关联公司如要改变您个人信息的处理
			目的，将再次征求您的授权和同意；
			</p>

			<p>
			5.合作伙伴间共享：我们仅会出于合法的、正当的、必要的、特定的、明确的
			以及为实现本政策中声明的目的，并在您明示同意的情况下，与合作伙伴共享
			必要的个人信息，或是汇总后的信息、匿名化后的信息或其他不会识别到您个
			人身份的信息（去标识化信息）。
			</p>

			<p>
			本款所述合作伙伴包括但不限于：
			</p>

			<p>
			（1）供应商和服务提供商。
			</p>

			<p>
			我们可能会与为我们提供服务的供应商共享信息。例如，我们分享匿名的位置
			和使用信息，以便为您提供加强版实时导航等功能和服务。
			</p>

			<p>
			（2）保险公司
			</p>

			<p>
			我们可能与保险合作伙伴及其附属机构分享信息，以向您提供诸如道路援助和
			汽车保险等服务。
			</p>

			<p>
			（3）第三方广告商
			</p>

			<p>
			我们与第三方广告商合作，向您展示我们认为您可能感兴趣的广告。如果您不
			希望收到个性化的广告，请访问他们的退出页面。
			</p>

			<p>
			（4）应用程序开发者
			</p>

			<p>
			我们可能会与其他应用程序开发者共享信息，例如，向合作伙伴如保险和自动
			驾驶汽车软件开发商共享图像数据信息以便其改善远程信息处理技术和计算机
			视觉算法。
			</p>

			<p>
			上述供应商、服务提供商、顾问、代理人等可能为我们提供技术基础设施服务，
			分析我们服务的使用方式，衡量广告和服务的有效性，提供客户支持服务和支
			付服务，进行学术研究和调查，采集和访问个人数据以进行数据统计及分析，
			或提供法律、财务、物流与技术顾问服务等，以便向您提供更好的用户体验、
			实现辅助驾驶及安全预警功能。我们所有的合作伙伴无权将共享的个人信息用
			于与产品或服务无关的其他用途。
			</p>

			<p>
			三、我们如何转让您的个人信息
			</p>

			<p>
			我们不会将您的个人信息转让给其他任何公司、组织和个人，但以下情形除外：
			</p>

			<p>
			1.随着公司业务的发展，我们及关联公司有可能进行合并、收购、资产转让或
			其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您
			个人信息的主体继续受本隐私政策约束，否则我们将要求该公司、组织和个人
			重新征得您的授权同意；
			</p>

			<p>
			2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；
			</p>

			<p>
			3.在获得您明确同意的情形下转让。
			</p>

			<p>
			四、信息披露
			</p>

			<p>
			我们仅会在以下情况下，公开披露您的个人信息：
			</p>

			<p>
			1．经您事先同意；
			</p>

			<p>
			2．为提供您所要求的产品和服务所必需；
			</p>

			<p>
			3．在法律、司法程序、诉讼或政府主管部门强制性要求的情况下，我们可能
			会公开披露您的个人信息；
			</p>

			<p>
			4．您出现违反中国有关法律、法规或者本软件服务协议或相关规则的情况，
			需要公开披露的；
			</p>

			<p>
			5．在本软件上进行的交易或支付所要求的必要信息；
			</p>

			<p>
			6.法律、法规规定的其他情形。
			</p>

			<p>
			五、Cookies 和同类技术
			</p>

			<p>
			Cookies通常包含标识符、站点名称以及一些号码和字符。我们和第三方合作
			伙伴使用cookies、像素标签或类似技术收集信息。我们的第三方合作伙伴，
			如数据分析云平台和广告合作伙伴，可能使用这些技术来收集您在不同时间和
			不同服务中的线上活动信息。
			</p>

			<p>
			您可根据自己的偏好管理或清除Cookies。但如果您这么做，您可能无法使用
			本软件的全部服务。
			</p>

			<p>
			六、我们如何保护您的个人信息
			</p>

			<p>
			我们已采取符合业界标准及法律规定的安全防护措施保护您提供的个人信息，
			防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会
			采取一切合理可行的措施，保护您的个人信息。
			</p>

			<p>
			我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数
			据遭到恶意攻击；我们会部署访问控制机制，遵照最小够用授权原则，确保只
			有授权人员才可访问个人信息；我们会举办安全和隐私保护培训课程，加强员
			工对于保护个人信息重要性的认识。
			</p>

			<p>
			我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延
			长保留期或受到法律的允许。
			</p>

			<p>
			尽管有前述安全措施，但同时也请您注意在信息网络上不存在 完善的安全措
			施 ，对此我们无法做出担保。
			</p>

			<p>
			如果不幸发生个人信息安全事件，我们会及时启动应急预案，防止安全事件扩
			大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、
			有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将
			要采取的措施等。
			</p>

			<p>
			七、未成年人个人信息保护
			</p>

			<p>
			本软件并非专门针对未成年人设计。不满十八周岁的未成年人在使用我们的服
			务时，需要未成年人本人或者监护人同意和授权。不满十四周岁的儿童在使用
			我们的服务时，需要父母等监护人阅读本政策，儿童应当在提交个人信息之前
			寻求父母等监护人的同意和指导。若您是儿童的父母或者监护人，当您对您所
			监护的儿童的个人信息有疑问时，您可以通过本政策中的联系方式与我们联系。
			</p>

			<p>
			八、隐私政策的适用范围
			</p>

			<p>
			本政策仅适用于本软件。肖衡的其他产品和服务将使用相关产品或服务的个人
			信息保护政策。特别需要说明的是，本政策不适用以下情况：
			</p>

			<p>
			1.您将我们的产品（或服务）嵌入到第三方产品（或服务），第三方产品（或
			服务）所收集的信息；
			</p>

			<p>
			2.在本软件中接入的第三方服务、广告或其他公司、组织或个人所收集的信息。
			我们在此提醒您，您在使用第三方产品与/或服务时，请仔细阅读第三方向您展
			示的相关用户协议和隐私政策，并妥善保管和谨慎提供您的个人信息。
			</p>

			<p>
			九、变更和修订
			</p>

			<p>
			本政策可能变更。未经您明确同意，我们不会限制您按照本政策所应享有的权
			利。
			</p>

			<p>
			我 们 将 把 对 本 政 策 的 任 何 变 更 公 布 在 我 们 的 网 站 上
			（www.zuojiaapp.com），更新后的版本在网站公告后生效。如果我们实质
			性地变更我们使用或分享个人信息的方式，我们还将通过软件通知、电子邮箱
			等方式及时向您进行提示。
			</p>

			<p>
			十、如何联系我们
			</p>

			<p>
			如果您对本政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请通
			过以下方式与我们联系：
			</p>

			<p>
			联系邮箱：support@trydriver.com
			</p>

			<p>
			地址：上海市松江区新效路258号1号楼204室
			</p>

			<p>
			在一般情况下，我们会在15个工作日内对您的请求予以答复。
			</p>

			<p>
			对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的
			请求，我们将酌情收取一定成本费用。
			</p>

			<p>
			十一、隐私政策的生效
			</p>

			<p>
			本政策版本更新日期为【2022】年【5】月【9】日，将于【2022】年【5】
			月【10】日正式生效。
			</p>
		</section>
	);
};

export default TermsAndConditionsCH;

import React from 'react';
import { Seo, PageHeader, PageFooter } from '../components';
import {
	PartnerBanner,
	PartnerMiddle,
	PartnerWorks,
	PartnerFeatures,
	PartnerRegister,
} from '../screens';

const PartnerV2 = ({ organization, icon, jotId, jotUrl }) => {
	return (
		<div className="partner">
			<Seo title={organization} />
			<PageHeader />
			<PartnerBanner organization={organization} icon={icon} />
			<PartnerMiddle organization={organization} />
			<PartnerWorks organization={organization} />
			<PartnerFeatures organization={organization} />
			<PartnerRegister jotId={jotId} jotUrl={jotUrl} />
			<PageFooter />
		</div>
	);
};

export default PartnerV2;

import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'gatsby';
import AppStoreButtons from '../components/app-store-buttons';
import DownloadButton from '../components/download-button';
import HeaderBanner from '../components/header-banner';
import GreenLogo from '../images/driver-logo-green-white.png';
import { DriverLogo } from '../images';

const THRESHOLD = 50;

const PageHeader = ({ solid, showBanner }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const [isOpen, setIsOpen] = useState(false);
	const [isSticking, setIsSticking] = useState(false);

	const onScroll = useCallback(() => {
		const y = window.pageYOffset;

		if (y >= THRESHOLD && !isSticking) {
			setIsSticking(true);
		} else if (y < THRESHOLD && isSticking) {
			setIsSticking(false);
		}
	}, [isSticking]);

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [onScroll]);

	const toggleMenuOpen = () => {
		setIsOpen(!isOpen);
	};

	const classes = classNames({
		solid,
		open: isOpen,
		sticking: isSticking,
		['green-bg']: pathname.startsWith('/careers'),
	}, 'driver-header');

	const wrapperClasses = classNames({
		['max-width']: pathname.startsWith('/careers'),
	}, 'header-wrapper');

	const getLogoSrc = () => {
		switch (pathname) {
		case '/careers':
		case '/careers/':
			return GreenLogo;
		default:
			return DriverLogo;
		}
	};

	return (
		<header className={classes}>
			{showBanner ? (<HeaderBanner />) : null}

			<div className={wrapperClasses}>
				<div className="main-wrapper">
					<div className="logo">
						<Link to="/">
							<img
								src={getLogoSrc()}
								width="160"
								alt={t('common.driver-technologies')}
							/>
						</Link>
					</div>

					<div
						className="hamburger"
						role="button"
						tabIndex="0"
						onClick={toggleMenuOpen}
						onKeyDown={toggleMenuOpen}
						aria-label="Menu">
						<div />
					</div>
				</div>

				<nav>
					<ul className="menu">
						<li>
							<Link to="/pricing">
								{t('navigation.pricing')}
							</Link>
						</li>
						<li>
							<Link to="/safety-innovation">
								{t('navigation.safety-innovation')}
							</Link>
						</li>
					</ul>

					<AppStoreButtons />

					<DownloadButton />
				</nav>
			</div>
		</header>
	);
};

PageHeader.defaultProps = {
	solid: false,
	showBanner: false,
};

export default PageHeader;

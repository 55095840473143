import i18n from 'i18next';
import {
	VideoIcon,
	PrivacyIcon,
	RoadsideIcon,
	GasIcon,
	MountIcon,
	CloudIcon,
	FCWIcon,
} from '../images';

export const MarbleFeatureList = [
	{
		heading: i18n.t('partner.features.hd-video-recording.heading'),
		context: i18n.t('partner.features.hd-video-recording.context'),
		icon: VideoIcon,
	},
	{
		heading: i18n.t('partner.features.privacy.heading'),
		context: i18n.t('partner.features.privacy.context'),
		icon: PrivacyIcon,
	},
	{
		heading: i18n.t('partner.features.free-roadside.heading'),
		context: i18n.t('partner.features.free-roadside.context'),
		icon: RoadsideIcon,
	},
	{
		heading: i18n.t('partner.features.gas-discounts.heading'),
		context: i18n.t('partner.features.gas-discounts.context'),
		icon: GasIcon,
	},
	{
		heading: i18n.t('partner.features.free-cloud.heading'),
		context: i18n.t('marble.free-cloud'),
		icon: CloudIcon,
	},
	{
		heading: i18n.t('partner.features.forward-collision.heading'),
		context: i18n.t('partner.features.forward-collision.context'),
		icon: FCWIcon,
	},
];

export const DefaultFeatureList = [
	{
		heading: i18n.t('partner.features.hd-video-recording.heading'),
		context: i18n.t('partner.features.hd-video-recording.context'),
		icon: VideoIcon,
	},
	{
		heading: i18n.t('partner.features.privacy.heading'),
		context: i18n.t('partner.features.privacy.context'),
		linkHeading: i18n.t('partner.features.privacy.link'),
		icon: PrivacyIcon,
	},
	{
		heading: i18n.t('partner.features.free-roadside.heading'),
		context: i18n.t('partner.features.free-roadside.context'),
		icon: RoadsideIcon,
	},
	{
		heading: i18n.t('partner.features.gas-discounts.heading'),
		context: i18n.t('partner.features.gas-discounts.context'),
		icon: GasIcon,
	},
	{
		heading: i18n.t('partner.features.free-dash-mount.heading'),
		context: i18n.t('partner.features.free-dash-mount.context'),
		icon: MountIcon,
	},
	{
		heading: i18n.t('partner.features.free-cloud.heading'),
		context: i18n.t('partner.features.free-cloud.context'),
		icon: CloudIcon,
	},
	{
		heading: i18n.t('partner.features.forward-collision.heading'),
		context: i18n.t('partner.features.forward-collision.context'),
		icon: FCWIcon,
	},
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import imgDesktop from '../images/premium-banner-img-desktop.png';
import imgDesktopMin from '../images/premium-banner-img-desktop-min.png';
import imgMobile from '../images/premium-banner-img-mobile.png';
import imgMobileMin from '../images/premium-banner-img-mobile-min.png';
import logo from '../images/driver-premium-logo-white.svg';
import icCloud from '../images/ic-premium-features-cloud.svg';
import icSafety from '../images/ic-premium-features-safety.svg';
import icRoadside from '../images/ic-premium-features-roadside.svg';
import icGas from '../images/ic-premium-features-gas.svg';

const HeaderBanner = () => {
	const { t } = useTranslation();

	return (
		<div className="header-banner-wrapper">
			<div className="img-wrapper">
				<img
					src={imgDesktop}
					className="img-desktop"
					alt="Driver Premium"
				/>
				<img
					src={imgDesktopMin}
					className="img-desktop-min"
					alt="Driver Premium"
				/>
				<img
					src={imgMobile}
					className="img-mobile"
					alt="Driver Premium"
				/>
				<img
					src={imgMobileMin}
					className="img-mobile-min"
					alt="Driver Premium"
				/>
			</div>

			<div className="content-wrapper">
				<div className="heading-wrapper">
					<img src={logo} alt="Driver Premium" />
					<h5>Get even more from your everyday driving companion!</h5>
				</div>

				<div className="details-wrapper">
					<ul>
						<li>
							<img src={icCloud} alt="Data Backed Up to DriverCloud" />
							<span>Data Backed Up to DriverCloud</span>
						</li>
						<li>
							<img src={icSafety} alt="Safety Alerts" />
							<span>Safety Alerts</span>
						</li>
						<li>
							<img src={icRoadside} alt="Roadside Assistance (U.S. Only)" />
							<span>Roadside Assistance (U.S. Only)</span>
						</li>
						<li>
							<img src={icGas} alt="Gas Savings (U.S. Only)" />
							<span>Gas Savings (U.S. Only)</span>
						</li>
					</ul>
				</div>

				<div className="action-wrapper">
					<Link to="/driver-premium">
						<button className="teal">
							{t('common.learn-more')}
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

HeaderBanner.defaultProps = {};

export default HeaderBanner;